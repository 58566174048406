import React, {
  ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { Link } from 'gatsby';
import * as s from '../../styles/main.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '../context/PageContext';
import { AccountHeaderMenuQuery } from 'graphql-types';
import { localizePath } from '../items/LotCardGrid';
import { UseFirebaseContext } from '../context/FirebaseContext';
interface LayoutProps {
  // children: ReactNode;
  // data: any;
  //userInfos: any;
}

export const AccountHeaderMenu: React.FC<LayoutProps> = ({}) => {
  const data = useStaticQuery<AccountHeaderMenuQuery>(accountHeaderQuery);
  const fireBaseContext = UseFirebaseContext();
  // console.log('@@@@@@@@@@@@@@@@@@ AccountHeaderMenu data ', data);
  const { lang } = usePageContext();
  const hello = data?.allSanityAccountSettings?.nodes?.[0]._rawHelloLabel?.[lang];
  const close = data?.allSanityAccountSettings?.nodes?.[0]._rawCloseLabel?.[lang];
  const menuLabel = data?.allSanityAccountSettings?.nodes?.[0]._rawMenuLabel?.[lang];
  const menu = data?.allSanityAccountPage?.nodes;

  const [isOpen, setIsOpen] = useState(false);
  const isActive = useCallback(
    (slug) => {
      const isThisMenuItemActive = location.pathname.includes(slug);
      return isThisMenuItemActive;
    },
    [location.pathname],
  );

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <div
      id="AccountHeaderMenu"
      className={`
      ${s.width100}
      ${s.heightAuto}
    `}
    >
      <div
        className={`
        ${s.width100}
        ${s.height40Px}
        ${s.backGroundColorCream}
        ${s.alignItemsCenter}
     `}
      >
        <div
          onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
          className={`
          ${s.widthAuto}
          ${s.height40Px}
          ${s.colorWhite}
          ${s.fontSize12Px}
          ${s.fontFamilyPoppinsReg}
          ${s.backGroundColorBlack}
          ${s.textTransformUppercase}
          ${s.paddingLeft20Px}
          ${s.paddingRight20Px}
          ${s.alignItemsCenter}
          ${s.cursorPointer}
        `}
        >
          {isOpen ? <>{close}</> : <>{menuLabel}</>}
        </div>

        <div
          className={`
          ${s.width100}
          ${s.colorDarkgreen}
          ${s.fontSize16Px}
          ${s.fontFamilyPaciencia}
          ${s.paddingLeft20Px}
          ${s.textTransformCapitalize}
        `}
        >
          {`${hello} ${fireBaseContext?.userInfo?.['First name']}`}
        </div>
      </div>

      {isOpen && (
        <div
          className={`${s.paddingBottom30Px} ${s.backGroundColorCream} ${s.positionAbsolute} ${s.zIndex1000}`}
        >
          <div
            className={`
                ${s.width100}
                ${s.height1Px}
                ${s.backGroundColorWhite}
              `}
          />
          {menu.map((item, index) => {
            return (
              <Link to={localizePath(`/account/${item?.slug?.current}`, lang)}>
                <div
                  className={`
                ${s.width100}
                ${s.colorDarkgreen}
                ${s.fontSize12Px}
                ${s.fontFamilyPoppinsBld}
                ${s.textTransformUppercase}
                ${s.textTransformUppercase}
                ${s.height40Px}
                ${s.wrapper40Px}
                ${s.displayFlex}
                ${s.alignItemsCenter}
                ${s.cursorPointer}
                ${isActive(item?.slug?.current) ? s.backGroundColorWhite : null}
              `}
                >
                  {item?.titleText?._rawTitle?.[lang]}
                </div>
                <div
                  className={`
                ${s.width100}
                ${s.height1Px}
                ${s.backGroundColorWhite}
              `}
                />
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const accountHeaderQuery = graphql`
  query accountHeaderMenu {
    allSanityAccountSettings {
      nodes {
        _rawHelloLabel
        _rawMenuLabel
        _rawCloseLabel
      }
    }

    allSanityAccountPage(sort: { orderRank: ASC }) {
      nodes {
        id
        slug {
          current
        }
        pageTitle
        titleText {
          _rawTitle
        }
      }
    }
  }
`;
