import * as s from '../../styles/main.module.scss';
import * as styles from './formblock.module.scss';

import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useMemo } from 'react';
import { HubspotForm } from '../hubspot/HubspotForm';
import { DataForm } from '../forms/DataForm';
import { SanityFormBlock } from '../../../graphql-types';
import { Spacer } from '../layout/Spacer';
import { useAvailableWidth } from '../../hooks/useAvailableWidth';
import { useGlobalWrapperClass } from 'src/hooks/useGlobalWrapperClass';
import { useWindowSize } from '../../hooks/useWindowSize';
import { usePageContext } from '../context/PageContext';
interface LayoutProps {
  item: SanityFormBlock;
  isInPopUp?: boolean;
  sanityMeetingLinks?:
    | ({
        __typename?: 'SanityMeetingHubspot' | undefined;
        _rawLanguage?: any;
        link?: string | null | undefined;
      } | null)[]
    | null
    | undefined;
}

export const FormBlock: React.FC<LayoutProps> = ({ item, isInPopUp, sanityMeetingLinks }) => {
  // console.log('FormBlock item', item);
  // console.log('FormBlock sanityMeetingLinks', sanityMeetingLinks);
  const isWindow = typeof window !== 'undefined';
  const isDocument = typeof document !== 'undefined';
  const windowSize = useWindowSize();
  const wrapperClass = useGlobalWrapperClass();
  const formLink = item?.formSelector;
  const { lang } = usePageContext();
  // console.log('Lang ', lang);

  const formId = useMemo(() => {
    // getHubspotForm('7e6fd642-b936-4182-b5d8-2ed0382acf9e');
    // getHubspotForm('b5d139e5-3840-4c55-9f2c-047ecbc7dacb');

    if (formLink === 'contact') {
      return lang === 'es'
        ? 'c763c938-2980-49e1-8b82-418454031a96'
        : 'c88c995a-ef27-4870-84ed-a974e8dbda40';
    }
    if (formLink === 'newsletter') {
      return lang === 'es'
        ? '7ef4fb13-a072-4b66-87df-fd88037bc7db'
        : '82ec1d87-0af7-4ce9-aaa3-24cb39bcd6ee';
    }
    if (formLink === 'meeting') {
      return lang === 'es'
        ? '914c99ba-9731-4b5d-a0d1-7a3e840da98f'
        : 'bfb3a669-5596-436b-98eb-b83032babff8';
    }
    if (formLink === 'registration') {
      return lang === 'es'
        ? 'a65f6c85-e05e-4ab6-9cb2-bc006c344fbf'
        : '396f5e3f-77b4-4452-a6a9-ac1707d1d0af';
    }
    if (formLink === 'caboblanco') {
      return lang === 'es'
        ? '39adda85-6e08-4bea-a744-5126199b7438'
        : '39adda85-6e08-4bea-a744-5126199b7438';
    } else {
      return lang === 'es'
        ? 'c763c938-2980-49e1-8b82-418454031a96'
        : 'c88c995a-ef27-4870-84ed-a974e8dbda40';
    }
  }, [formLink]);

  // console.log('formId ', formId);

  useEffect(() => {
    // if (isWindow && isDocument) {
    //   const script = document.createElement('script');
    //   script.src = 'https://js.hsforms.net/forms/v2.js';
    //   document.body.appendChild(script);
    //   script.addEventListener('load', () => {
    //     // @TS-ignore
    //     if (window.hbspt) {
    //       // @TS-ignore
    //       window.hbspt.forms.create({
    //         region: 'na1',
    //         portalId: '21307994',
    //         formId: formId,
    //         target: '#hubspotForm',
    //       });
    //     }
    //   });
    // }
  }, []);

  const isMobile = useMemo(() => {
    if (windowSize.width < 740) {
      return true;
    } else {
      return false;
    }
  }, [windowSize.width]);

  return (
    <>
      <div
        id="FormBlock"
        className={`${styles.wrapper} ${wrapperClass}  ${
          isInPopUp ? s.height100 : s.heightAuto
        }   ${isInPopUp ? s.flexDirectionColumn : s.flexDirectionRow} ${
          isInPopUp ? s.alignContentCenter : s.alignContentFlexStart
        } `}
      >
        {/* {!item.image?.asset?.gatsbyImageData && <Feuille className={styles.svg} />} */}

        <div
          id="FormBlockBox"
          className={`${s.width100}   ${isInPopUp ? s.height100 : s.heightAuto}   ${
            isInPopUp ? s.flexDirectionColumn : s.flexDirectionRow
          }  ${isInPopUp ? s.alignContentCenter : s.alignContentFlexStart} `}
        >
          {!isInPopUp && !isMobile && (
            <GatsbyImage
              image={item.image?.asset?.gatsbyImageData}
              alt={''}
              className={`${s.width50} ${s.aspectRatio1} `}
            />
          )}

          <div
            id="FormBlockDataForm"
            className={`${s.hideScrollBar}  ${isInPopUp ? s.maxWidth565Px : s.maxWidth100} ${
              isInPopUp || isMobile ? s.width100 : s.width50
            }   ${isInPopUp || isMobile ? s.paddingLeft0 : s.paddingLeft30Px}  ${
              isInPopUp ? s.height100 : s.heightAuto
            }  ${s.overflowYScroll}`}
          >
            <HubspotForm
              sanityMeetingLinks={sanityMeetingLinks}
              formId={formId}
              isInPopUp={isInPopUp ? isInPopUp : false}
              item={item}
            />
            {/* <DataForm
              formLink={formLink}
              isInPopUp={isInPopUp}
              message={item._rawSuccessMessage}
              item={item}
            /> */}
          </div>
        </div>

        {/* {item.image?.asset?.gatsbyImageData && <Feuille className={styles.svg} />} */}
      </div>

      {isInPopUp ? null : <Spacer />}
    </>
  );
};
