import React from 'react';
import Triangle from '../../assets/svgs/triangle.svg';
import * as styles from './submitButton.module.scss';
import { usePageContext } from 'src/components/context/PageContext';

interface Props {
  onSubmit?: () => void | null;
}

const SUBMIT = {
  en: 'SUBMIT',
  es: 'ENVIAR',
};

export const SubmitButton = ({ onSubmit }: Props) => {
  const { lang } = usePageContext();
  return (
    <div className={styles.wrapper}>
      {onSubmit !== null && onSubmit !== undefined ? (
        <button type={'submit'}>
          {SUBMIT?.[lang]}
          <Triangle className={`${styles.svg} `} />
        </button>
      ) : (
        <div className={styles.pseudoButton}>
          {SUBMIT?.[lang]}
          <Triangle className={`${styles.svg} `} />
        </div>
      )}
    </div>
  );
};
