import React, { ReactNode, useEffect, useLayoutEffect, useState, useCallback, useRef } from 'react';

import { Link } from 'gatsby';

import * as s from '../../styles/main.module.scss';
import { usePageContext } from '../context/PageContext';
import { SanityButton } from 'graphql-types';
import { localizePath } from './LotCard';
import * as styles from './buttonMore.module.scss';
import Triangle from '../../assets/svgs/triangle.svg';
// import { ReactComponent as TriangleDarkGrey } from '../../assets/svgs/triangle_DarkGrey.svg';
interface Props {
  button: SanityButton;
  color: string;
}

export const ButtonMore: React.FC<Props> = ({ button, color }) => {
  const { lang, formPopUpState, setFormPopUpState, setLoginDrawerState, setMenuState } =
    usePageContext();

  ////////////////////// Lots Cards inline list ///////////////////////////////////////////////////////////////

  // const iconSrc = color === 'white' ? '/svgs/triangle.svg' : '/svgs/triangle_DarkGrey.svg';

  const ctaText = button?._rawCta?.[lang];

  const handleClick = (formStatus) => {
    setFormPopUpState({ popupState: formStatus });
    if (formStatus !== 'closed') {
      setLoginDrawerState({ loginDrawer: false });
      setMenuState({ menuStateOpen: false });
    }
  };

  const insertButton = () => {
    return (
      <div
        id="ButtonMore"
        className={`
          ${styles.button}
          ${s.height40Px}
          ${s.cursorPointer}
          ${s.displayFlex}
          `}
      >
        <div
          className={`
             ${s.height40Px}
             ${s.widthFitContent}
            ${s.textTransformUppercase}
            ${s.fontFamilyPoppinsBld}
            ${s.textAlignRight}
            ${s.fontSize12Px}
            ${color === 'white' ? s.colorWhite : s.colorDarkgrey}
            ${s.lineHeight1}
            ${s.letterSpacing0Px}
            ${s.floatLeft}
            ${s.justifyContentCenter}
            ${s.alignItemsCenter}
            ${s.whiteSpaceNowrap}
            `}
        >
          {ctaText}
        </div>
        <div
          className={`
           ${s.height40Px}
           ${s.widthFitContent}
            ${s.floatLeft}
            ${s.justifyContentCenter}
            ${s.alignItemsCenter}
            ${s.marginLeft10Px}

            ${color === 'white' ? styles.svgColorWhite : styles.svgColorDarkGrey}
            `}
        >
          {/* <svg
            className={`
            ${s.height8Px}
            ${s.width8Px}
            `}
            src={iconSrc}
            alt="icon-triangle"
          /> */}
          <Triangle className={`${styles.svg} `} />
        </div>
      </div>
    );
  };

  const getTargetType = () => {
    const siteLink = button?.siteLink?.slug?.current;
    const externalLink = button?.externalLink as string;

    const formLink = button?.formBlockRef?.formSelector;
    const siteLinkLength = siteLink?.length;
    const externalLinkLength = externalLink?.length;
    const formLinkLength = formLink?.length;

    if (siteLink !== undefined && siteLink !== null) {
      // console.log('siteLink !== undefined && siteLink !== null ', siteLink);
      if (button?.siteLink?._type === 'ecoVilla') {
        return (
          <Link
            id="aButtonMore"
            style={{ width: 'unset' }}
            className={`${s.widthUnset}`}
            to={localizePath('/ecovillas/' + siteLink, lang)}
          >
            {insertButton()}
          </Link>
        );
      } else if (button?.siteLink?._type === 'lot') {
        return (
          <Link
            id="aButtonMore"
            style={{ width: 'unset' }}
            className={`${s.widthUnset}`}
            to={localizePath('/lots/' + siteLink, lang)}
          >
            {insertButton()}
          </Link>
        );
      } else if (button?.siteLink?._type === 'accountPage') {
        return (
          <Link
            id="aButtonMore"
            style={{ width: 'unset' }}
            className={`${s.widthUnset}`}
            to={localizePath('/account/' + siteLink, lang)}
          >
            {insertButton()}
          </Link>
        );
      } else {
        return (
          <Link
            id="aButtonMore"
            style={{ width: 'unset' }}
            className={`${s.widthUnset}`}
            to={localizePath('/' + siteLink, lang)}
          >
            {insertButton()}
          </Link>
        );
      }
    }

    if (formLink !== undefined && formLink !== null) {
      return (
        <div
          style={{ width: 'unset' }}
          className={`${s.widthUnset}`}
          onClick={() => handleClick(formLink)}
        >
          {insertButton()}
        </div>
      );
    }

    if (externalLink !== undefined && externalLink !== null) {
      return (
        <a
          id="aButtonMore"
          style={{ width: 'unset' }}
          className={`${s.widthUnset}`}
          href={externalLink}
          target="_blank"
        >
          {insertButton()}
        </a>
      );
    }
  };

  ////////////////////// Return of Component ///////////////////////////////////////////////////////////////

  return <>{getTargetType()}</>;
};
