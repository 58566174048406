import React, { useState, useRef } from 'react';

import { Link } from 'gatsby';

import * as s from '../../styles/main.module.scss';
import { usePageContext } from '../context/PageContext';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useGlobalWrapperVal } from '../../hooks/useGlobalWrapperVal';
interface Props {
  item: any;
}

export const localizePath = (path: string, lang: string) => {
  if (lang === 'es') return '/es' + path;
  return path;
};

export const LotCardGrid: React.FC<Props> = ({ item }) => {
  const wrapperVal = useGlobalWrapperVal();
  const whiteLeaveSrc = '/svgs/GrandeFeuilleBlanche_Full.svg';
  const thisCard = useRef<HTMLDivElement>(null);
  const { lang } = usePageContext();
  const windowSize = useWindowSize();
  const [openState, setOpenState] = useState(false);
  ////////////////////// Lots Crads inline list ///////////////////////////////////////////////////////////////

  const id = item?.id;
  const sku = item?.sku;
  const ecovillaBelongingName = item?.ecovillaLink?.name;
  const slug = item?.slug?.current;

  const lotSize = `${item?._rawLotSize?.en} - ${item?._rawLotSize?.es}`;
  const photo = item?.photos[0]?.asset?.gatsbyImageData;
  const casaName = item?.buildingInformations?.name;
  const lotDescription = item?._rawDescription?.[lang];
  const clickable = item?.clickable;

  ////////////////////// Get BackGroundColor by Lot ///////////////////////////////////////////////////////////////

  const getBackGroundColor = () => {
    // console.log('item?.communtyLink?.color', item?.communtyLink?.color);
    if (item?.communtyLink?.color) {
      return item?.communtyLink?.color;
    } else {
      return '#384735';
    }
  };

  ////////////////////// Return of Component ///////////////////////////////////////////////////////////////

  const insertEcovillaName = () => {
    if (casaName) {
      return casaName + ' | ' + ecovillaBelongingName;
    } else {
      return ecovillaBelongingName;
    }
  };

  const showDescription = (sku) => {
    // const MainMenuLinksBoxInPosLeft = thisCard?.current?.getBoundingClientRect(). as number;
    //  console.log('MainMenuLinksBoxInPosLeft : ', e);
    // console.log('showDescription', sku);
    setOpenState(true);
  };

  const hideDescription = (sku) => {
    // console.log('hideDescription', sku);
    setOpenState(false);
  };

  const getWidth = () => {
    const availableWidth = windowSize.width - 2 * wrapperVal;
    // console.log("availableWidth : ",availableWidth)
    const numberOfCards = Math.floor(availableWidth / 300);
    // console.log("numberOfCards : ",numberOfCards)
    const guttersCount = (numberOfCards - 1) * 20;
    // console.log("guttersCount : ",guttersCount)
    const finalCardWidth = (availableWidth - guttersCount) / numberOfCards;
    // console.log("finalCardWidth : ",finalCardWidth )
    return finalCardWidth;
  };

  const getHeaderHeight = () => {
    if (windowSize.width >= 1084) {
      const HeaderHeight = 75 + 30 + 2;
      return HeaderHeight;
    } else {
      const HeaderHeight = 64 + 30 + 2;
      return HeaderHeight;
    }
  };
  const getTitleHeight = () => {
    if (windowSize.width >= 740) {
      const TitleHeight = 136;
      return TitleHeight;
    } else {
      const TitleHeight = 105;
      return TitleHeight;
    }
  };

  const getMaxHeight = () => {
    const maxHeight = windowSize.height - getTitleHeight() - getHeaderHeight();
    return maxHeight;
  };
  const getHeight = () => {
    return getWidth() * (3 / 4) + 100;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const clickableOrNot = () => {
    if (clickable) {
      return <Link to={localizePath('/lots/' + slug ?? '', lang)}>{cardContent()}</Link>;
    } else {
      return cardContent();
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const cardContent = () => {
    return (
      <div
        style={{
          width: getWidth(),
          height: getHeight(),
          maxHeight: getMaxHeight(),
        }}
        id="LotCardBox"
        key={id}
        className={`
      ${s.borderClearGrey}
      `}
      >
        <div
          id="WhitheLeave"
          style={{ top: 10, left: 10 }}
          className={`
            ${s.height20Px}
            ${s.width20Px}
            ${s.positionAbsolute}
            ${s.zIndex10}
        `}
        >
          <img
            className={`
          ${s.width100}
          ${s.height100}
          `}
            src={whiteLeaveSrc}
          />
        </div>
        <div
          id="LotCardImageBox"
          className={`
        ${s.width100}
        ${s.aspectRatio4On3}
      `}
        >
          <GatsbyImage
            className={`
          ${s.width100}

          ${s.aspectRatio4On3}
          `}
            image={photo}
            alt="logo"
          />
        </div>

        <div
          id="LotInformations"
          className={`
       ${s.positionAbsolute}
       ${s.bottom0}
       ${s.backGroundColorWhite}

       `}
        >
          <div
            id="LotCardInfoFirstLine"
            className={`
        ${s.alignItemsCenter}
        ${s.flexDirectionRow}
        ${s.minWidth0}
        ${s.width100}
        ${s.borderTopClearGrey}
        ${s.height50Px}
       
        `}
          >
            <div
              id="SkuBox"
              style={{ backgroundColor: getBackGroundColor() }}
              className={`
              ${s.widthAuto}
              ${s.alignItemsCenter}
              ${s.textOverflowEllipsis}
              ${s.height50Px}
              ${s.flexGrow1}
              ${s.wrapper20Px}

          `}
            >
              <p
                className={`             
              ${s.fontFamilyPaciencia}
              ${s.colorWhite}
              ${s.fontSize18Px}
              ${s.whiteSpaceNowrap}
              ${s.lineHeight1And2}
              `}
              >
                {sku}
              </p>
            </div>
            <div
              id="LotSizeBox"
              className={`
            ${s.overflowHidden}
            ${s.alignItemsCenter}
            ${s.marginLeftAuto}
            ${s.textOverflowEllipsis}
            ${s.height50Px}
            ${s.wrapper20Px}
            ${s.borderLeftClearGrey} 
            `}
            >
              <p
                className={`
              ${s.textAlignCenter}
              ${s.colorDarkgrey}
              ${s.fontFamilyPaciencia}
              ${s.fontSize18Px}
              ${s.whiteSpaceNowrap}
              ${s.lineHeight1And2}
              `}
              >
                {lotSize}
              </p>
            </div>
          </div>
          <div
            id="InfoSecondLineBis"
            className={`
          ${openState ? s.visibilityVisible : s.visibilityHidden}
          ${openState ? s.heightAuto : s.height0Px}
          ${s.height0Px}
          ${s.borderTopClearGrey}
          ${s.alignItemsCenter}
          ${s.width100}
         `}
          >
            <div
              id="EcovillaDescriptionBox"
              className={`
            ${s.paddingTop20Px}
            ${s.paddingBottom20Px}
            ${s.wrapper20Px}
            ${s.heightAuto}
          `}
            >
              <p
                className={`
              ${s.overflowHidden}
              ${s.alignItemsCenter}
              ${s.textAlignLeft}
              ${s.marginLeftAuto}
              ${s.textOverflowEllipsis}
              ${s.fontFamilyPoppinsReg}
              ${s.colorDarkgrey}
              ${s.fontSize12Px}
              ${s.lineHeight1And1}
              ${s.whiteSpacePreWrap}
           `}
              >
                {lotDescription}
              </p>
            </div>
          </div>
          <div
            id="InfoSecondLine"
            className={`
         ${s.wrapper20Px}
         ${s.height50Px} 
         ${s.borderTopClearGrey}
         ${s.alignItemsCenter}
         ${s.width100}
         
         `}
          >
            <div
              id="EcovillaNameBox"
              className={`
          ${s.alignItemsCenter}
          ${s.widthAuto}
          ${s.textAlignLeft}
          ${s.height50Px}
          ${s.colorDarkgrey}
          ${s.fontFamilyPaciencia}
          ${s.fontSize18Px}
          ${s.lineHeight1And2}
          ${s.letterSpacing0Px} 
          `}
            >
              {insertEcovillaName()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div
      id="LotCard"
      key={id}
      ref={thisCard}
      onMouseOver={() => showDescription(sku)}
      onMouseOut={() => hideDescription(sku)}
      style={{
        width: getWidth(),
        height: getHeight(),
        maxHeight: getMaxHeight(),
      }}
      className={`
      ${s.flexDirectionColumn}
      ${s.scrollSnapAlignStart}
      ${s.floatLeft}
      ${s.justifyContentCenter}
      ${s.alignItemsFlexStart}
     

    
    
      `}
    >
      {clickableOrNot()}
      <Link to={localizePath('/lots/' + slug ?? '', lang)}></Link>
    </div>
  );
};
