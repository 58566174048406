import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import { usePageContext } from '../context/PageContext';
import { FooterQuery, SanityFooterConnection } from 'graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as s from '../../styles/main.module.scss';
import { useWindowSize } from '../../hooks/useWindowSize';
import { localizePath } from '../items/LotCard';

interface LayoutProps {
  //children: ReactNode;
}

export const Footer: React.FC<LayoutProps> = () => {
  const data = useStaticQuery<FooterQuery>(footerQuery);
  const windowSize = useWindowSize();
  const copyRightText = data.allSanityFooter.nodes[0]?.copyrightText;
  const tel = data.allSanityFooter.nodes[0]?.telephone;
  const place = data.allSanityFooter.nodes[0]?.place;
  const email = data.allSanityFooter.nodes[0]?.email;
  const legalsTarget = data.allSanityFooter.nodes[0]?.legalsTarget?.slug?.current;
  const footerLogo = data?.allSanityFooter?.nodes[0]?.image?.asset?.gatsbyImageData;
  const linkList = data?.allSanityFooter?.nodes[0]?.socialsLinkList;
  const { lang } = usePageContext();

  ////////////Site Map Links //////////////////////////////////////////////////////////

  const insertLinks = linkList?.map((item, index) => {
    const iconSocialSrc = item?.image?.asset?.gatsbyImageData;
    const target = item?.url as string;

    return (
      <div
        id="SocialsLinkItem"
        key={item?.url}
        className={`
        
       `}
      >
        <a href={target} target="_blank">
          <div
            id="SocialItemBox"
            className={`
            ${s.width30Px}
            ${s.height30Px}
           `}
          >
            <GatsbyImage
              className={`
              ${s.width30Px}
              ${s.height30Px}
            `}
              image={iconSocialSrc}
              objectFit="contain"
              alt="background"
            />
          </div>
        </a>
      </div>
    );
  });
  //////////////////////////////////////////////////////////////////////////////////////

  const mobileFooter = () => {
    return (
      <>
        <div
          id="FirstRow"
          className={`
      ${s.width100}
      ${s.heightAuto}
      ${s.flexDirectionRow}
      ${s.justifyContentFlexEnd}
      ${s.alignItemsCenter}
      
    
    }`}
        >
          <Link to={localizePath('/', lang)}>
            <div
              id="FooterLogoBox"
              className={`
            ${s.width151Px}
            ${s.height56Px}
            `}
            >
              <GatsbyImage
                className={`
              ${s.width151Px}
              ${s.height56Px}
              `}
                image={footerLogo}
                objectFit="contain"
                alt="background"
              />
            </div>
          </Link>
        </div>
        <div
          id="Socials"
          className={`
          ${s.widthFitContent}
          ${s.marginRightAuto}
          ${s.marginTop50Px}
          ${s.marginBottom50Px}
          ${s.height30Px}
          ${s.flexDirectionRow}
          ${s.justifyContentFlexStart}
          ${s.alignItemsCenter}
        }`}
        >
          {insertLinks}
        </div>
        <div
          id="secondRow"
          className={`
        ${s.width100}
        ${s.heightAuto}
        ${s.flexDirectionRow}
        ${s.justifyContentFlexEnd}
        ${s.alignItemsFlexEnd}
        ${s.marginTopAuto}
        }`}
        >
          <div
            id="FooterInfos"
            className={`
          ${s.heightAuto}
          ${s.textTransformUppercase}
          ${s.textAlignLeft}
          ${s.letterSpacing1And5Px}
          ${s.fontSize12Px}
          ${s.fontFamilyPoppinsReg}
          ${s.colorClearGrey}
          ${s.justifyContentFlexStart}
          ${s.alignItemsFlexStart}
          ${s.flexDirectionColumn}
          `}
          >
            <a href={'tel:' + tel} target="_blank" className={`${s.marginTop10Px}`}>
              {tel}
            </a>
            <p className={`${s.marginTop10Px}`}>{place}</p>
            <a href={'mailto:' + email} target="_blank" className={`${s.marginTop10Px}`}>
              {email}
            </a>
          </div>
        </div>
        <div
          id="FooterCopyrightBox"
          className={`
          ${s.heightAuto}
          ${s.textTransformUppercase}
          ${s.letterSpacing1And5Px}
          ${s.fontSize12Px}
          ${s.fontFamilyPoppinsReg}
          ${s.colorClearGrey}
          ${s.justifyContentFlexEnd}
          ${s.alignItemsFlexEnd}
          ${s.marginLeftAuto}
          ${s.marginTop50Px}
        
          ${s.textAlignRight}
          ${s.whiteSpacePreWrap}
          `}
        >
          <div>{copyRightText}</div>
          {/* <Link to={localizePath('/' + legalsTarget, lang)}>{copyRightText}</Link> */}
        </div>
      </>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////

  const desktopFooter = () => {
    return (
      <>
        <div
          id="FirstRow"
          className={`
      ${s.width100}
      ${s.heightAuto}
      ${s.flexDirectionRow}
      ${s.justifyContentFlexEnd}
      ${s.alignItemsCenter}
      
    
    }`}
        >
          <Link to={localizePath('/', lang)}>
            <div
              id="FooterLogoBox"
              className={`
            ${s.width151Px}
            ${s.height56Px}
            `}
            >
              <GatsbyImage
                className={`
              ${s.width151Px}
              ${s.height56Px}
              `}
                image={footerLogo}
                objectFit="contain"
                alt="background"
              />
            </div>
          </Link>
          <div
            id="Socials"
            className={`
          ${s.widthAuto}
          ${s.height30Px}
          ${s.flexDirectionRow}
          ${s.justifyContentFlexEnd}
          ${s.alignItemsCenter}
        }`}
          >
            {insertLinks}
          </div>
        </div>
        <div
          id="secondRow"
          className={`
        ${s.width100}
        ${s.heightAuto}
        ${s.flexDirectionRow}
        ${s.justifyContentFlexEnd}
        ${s.alignItemsFlexEnd}
        ${s.marginTopAuto}
        }`}
        >
          <div
            id="FooterInfos"
            className={`
          ${s.heightAuto}
          ${s.textTransformUppercase}
          ${s.textAlignLeft}
          ${s.letterSpacing1And5Px}
          ${s.fontSize12Px}
          ${s.fontFamilyPoppinsReg}
          ${s.colorClearGrey}
          ${s.justifyContentFlexStart}
          ${s.alignItemsFlexStart}
          ${s.flexDirectionColumn}
          `}
          >
            <a href={'tel:' + tel} target="_blank" className={`${s.marginTop10Px}`}>
              {tel}
            </a>
            <p className={`${s.marginTop10Px}`}>{place}</p>
            <a href={'mailto:' + email} target="_blank" className={`${s.marginTop10Px}`}>
              {email}
            </a>
          </div>

          <div
            id="FooterCopyrightBox"
            className={`
          ${s.heightAuto}
          ${s.textTransformUppercase}
          ${s.letterSpacing1And5Px}
          ${s.fontSize12Px}
          ${s.fontFamilyPoppinsReg}
          ${s.colorClearGrey}
          ${s.justifyContentFlexEnd}
          ${s.alignItemsFlexEnd}
          ${s.marginLeftAuto}
          ${s.textAlignRight}
          ${s.whiteSpacePreWrap}

          `}
          >
            {/* <Link to={localizePath('/' + legalsTarget, lang)}>{copyRightText}</Link> */}
            <div>{copyRightText}</div>
          </div>
        </div>
      </>
    );
  };

  ////////////Component  Render//////////////////////////////////////////////////////////
  return (
    <div
      id="Footer"
      className={`
      ${s.width100}
      ${s.heightAuto}
      ${s.backGroundColorVeryDarkGreen}
      ${windowSize.width < 800 ? s.wrapper20Px : s.wrapper45Px} 
      ${s.paddingTop30Px}
      ${s.paddingBottom96Px}
      ${s.marginBottom60Px}
      ${s.flexDirectionColumn}
      ${s.alignItemsFlexEnd}
      `}
    >
      {windowSize.width >= 750 ? desktopFooter() : mobileFooter()}
    </div>
  );
};

export const footerQuery = graphql`
  query footer {
    allSanityFooter {
      nodes {
        id
        socialsLinkList {
          url
          title
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        legalsTarget {
          slug {
            current
          }
        }
        copyrightText
        email
        place
        telephone
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
