import React, { ReactNode, useEffect, useLayoutEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'gatsby';
import * as s from '../../styles/main.module.scss';
import { graphql, useStaticQuery } from 'gatsby';

import { HeaderQuery, SanityHeaderLogoConnection } from 'graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { gsap } from 'gsap';
import { usePageContext } from '../context/PageContext';
import { useWindowSize } from '../../hooks/useWindowSize';
import { HeaderDesktop } from './HeaderDesktop';
import { HeaderMobile } from './HeaderMobile';

interface LayoutProps {
  // children: ReactNode;
}

export const Header: React.FC<LayoutProps> = ({}) => {
  const StripeTop = useRef<HTMLDivElement>(null);
  const StripeMiddle1 = useRef<HTMLDivElement>(null);
  const StripeMiddle2 = useRef<HTMLDivElement>(null);
  const StripeBottom = useRef<HTMLDivElement>(null);
  const HeaderBackground = useRef<HTMLDivElement>(null);

  const data = useStaticQuery<HeaderQuery>(headerQuery);

  const { menuState, setMenuState } = usePageContext();
  const { pathState, setPathState, originalPath } = usePageContext();
  const isWindow = typeof window !== 'undefined';
  const isNotHeroPage = useMemo(() => {
    return originalPath?.includes('/lots/');
  }, [originalPath]);

  const [scrollState, setScrollState] = useState(isNotHeroPage ? true : false);

  const dataMenu = data;

  const windowSize = useWindowSize();

  useEffect(() => {
    if (isWindow) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [windowSize]);

  const handleScroll = () => {
    if (windowSize.width >= 1070 && window.pageYOffset > windowSize.height - 120) {
      setScrollState(true);
    } else if (windowSize.width < 1070 && window.pageYOffset > windowSize.height - 94) {
      setScrollState(true);
    } else if (originalPath?.includes('/lots/')) {
      setScrollState(true);
    } else {
      setScrollState(false);
    }
  };

  const desktopMenu = (width) => {
    return <HeaderDesktop data={dataMenu} scrollState={scrollState} />;
  };

  const mobileMenu = (width) => {
    return <HeaderMobile data={dataMenu} scrollState={scrollState} />;
  };

  return (
    <div
      id="Header"
      className={`
      ${s.width100}
      ${s.heightAuto}
      ${s.positionFixed}
      ${s.zIndex1000}
     
    `}
    >
      <div
        id="HeaderContentRow"
        className={`
        ${s.width100}
        ${s.heightAuto}
        ${s.flexDirectionRow}
        ${s.justifyContentFlexEnd}
        ${s.positionAbsolute}
        ${s.zIndex2}
    

    `}
      >
        <div id="Menu">
          {windowSize.width >= 1322 ? desktopMenu(windowSize.width) : mobileMenu(windowSize.width)}
        </div>
      </div>
      {/* <div
        ref={HeaderBackground}
        id="HeaderBackground"
        className={`
        ${s.width100}
        ${windowSize.width >= 1070 ? s.height90Px : s.height64Px}
        ${menuState.menuStateOpen === false ? s.backGroundColorWhite : s.backGroundColorDarkgreen} 

    `}
      /> */}
    </div>
  );
};

export const headerQuery = graphql`
  query header {
    allSanitySetUpACallButton {
      nodes {
        id
        _rawCtaText
      }
    }

    allSanityHeaderLogo {
      nodes {
        logoWhite {
          asset {
            gatsbyImageData(placeholder: NONE)
          }
        }
        logoDark {
          asset {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
    allSanityDynamicPage(sort: { orderRank: ASC }) {
      nodes {
        id
        pageTitle
        _rawPageTitleMenu
        orderRank
        slug {
          current
        }

        childPagesReferenceList {
          id
          pageTitle
          _rawPageTitleMenu
          slug {
            current
          }
        }
        pageSubdirectory {
          slug {
            current
          }
        }
        activeInMainMenu
        activeInNewMenu
      }
    }
    allSanityEcoVilla(sort: { orderRank: ASC }) {
      nodes {
        id
        slug {
          current
        }
        name
      }
    }
  }
`;

// ${windowSize.width >= 750 ? s.paddingTopBottom30Px : s.paddingTopBottom16Px}
//${windowSize.width >= 750 ? s.wrapper40Px : s.wrapper20Px}
