import { useState, useEffect } from 'react';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    ratio: 1,
  });

  const isWindow = typeof window !== 'undefined';
  const isDocument = typeof document !== 'undefined';

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state

      if (isDocument) {
        const newRatio = document.body.clientWidth / document.body.clientHeight;
        setWindowSize({
          width: document.body.clientWidth,
          height: document.body.clientHeight,
          ratio: newRatio,
        });
      }
    }
    // Add event listener
    if (isWindow) {
      window.addEventListener('resize', handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);
  // Empty array ensures that effect is only run on mount
  // console.log(
  //   'SIZE  SIZE  SIZE  SIZE SIZE SIZE SIZE useWindowSize handleResize windowSize ',
  //   windowSize.width,
  // );
  return windowSize;
};
