import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import * as styles from './stringField.module.scss';

export const StringField = ({
  input,
  meta,
  required,
  label,
  fieldType,
  placeholder,
  children,
  name,
}: FieldRenderProps<string, HTMLElement>) => {
  // console.log('StringField ', label);
  // console.log('StringField meta', meta);
  // console.log('StringField input', input);

  return (
    <div className={`${styles.wrapper} ${fieldType === 'textarea' && styles.text}`}>
      <div className={styles.label}>
        <label>{required ? `${label}*` : label}</label>
      </div>
      <div data-error={Boolean((meta.error || meta.submitError) && meta.touched)}>
        {fieldType === 'textarea' ? (
          <textarea
            {...input}
            name={input.name}
            placeholder={placeholder}
            value={input.value}
            onChange={input.onChange}
          />
        ) : (
          <>
            <input
              {...input}
              type={fieldType}
              placeholder={placeholder}
              name={input.name}
              value={input.value}
              onChange={input.onChange}
            />
            {/* {(meta.error || meta.submitError) && meta.touched && (
              <span>{meta.error || meta.submitError}</span>
            )} */}
          </>
        )}
        {children}
      </div>
    </div>
  );
};
