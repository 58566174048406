import React, { ReactNode, useEffect, useLayoutEffect, useState, useRef } from 'react';

import * as s from '../../styles/main.module.scss';

import { usePageContext } from '../context/PageContext';
import { useWindowSize } from '../../hooks/useWindowSize';

interface LayoutProps {
  // children: ReactNode;
}

export const HeaderSpacer: React.FC<LayoutProps> = ({}) => {
  const [headerHeightState, setHeaderHeightState] = useState(0);

  const windowSize = useWindowSize();

  useEffect(() => {
    // if (window.location.pathname !== '/home' && window.location.pathname !== '/home/') {
    //   if (windowSize.width >= 750) {
    //     setHeaderHeightState(90);
    //   } else {
    //     setHeaderHeightState(65);
    //   }
    // }
    if (windowSize.width >= 1084) {
      setHeaderHeightState(75 + 30 + 2);
    } else {
      setHeaderHeightState(64 + 30 + 2);
    }
  }, [windowSize.width]);

  return <div id="HeaderSpacer" style={{ width: windowSize.width, height: 30 }}></div>;
};
