import React, { useState, useRef } from 'react';
import { FieldRenderProps } from 'react-final-form';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import UpArrow from '../../../assets/svgs/fleche.svg';
import * as styles from './selectField.module.scss';
interface SelectVal {
  value: string;
  label?: string;
}
interface Option {
  label: string;
  value: string;
}

interface SelectFieldProps extends FieldRenderProps<string, HTMLElement> {
  options: Option[];
  label: string;
  placeholder: string;
}

export const SelectField = ({
  label,
  meta,
  value,
  options,
  rest,
  input,
  placeholder,
  required,
  name,
}: FieldRenderProps<string, HTMLElement>) => {
  const filteredOptions = options.filter(
    (obj) => obj.value === 'es' || obj.value === 'fr' || obj.value === 'de' || obj.value === 'en',
  );

  const [menuOpen, setMenuOpen] = useState(false);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const DropdownIndicator = (props: DropdownIndicatorProps<true>) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className={styles.indicator}>
          <UpArrow className={menuOpen ? styles.iconDown : styles.iconUp} />
        </div>
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      fontSize: '12px',
      lineHeight: '1.2',
      color: state.isFocused ? '#100c08' : '#A8A8A8',
      fontWeight: 400,
      cursor: 'pointer',
      paddingLeft: 20,
    }),
    container: () => ({
      display: 'flex',
      flexDirection: 'column',
      gap: '6.25px',
      height: '38px',
      borderRadius: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'no-wrap',
      cursor: 'pointer',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #D6D6D6',
      padding: '0 10px',
      color: 'black',
      paddingTop: '2px',
      backgroundColor: menuOpen ? 'white' : 'transparent',
      borderRadius: 0,
    }),

    menu: () => ({
      backgroundColor: 'transparent',
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      left: '0',
      top: '40px',
      border: 'unset',
    }),
    menuList: () => ({
      border: '1px solid #D6D6D6',
      paddingLeft: '0',
      fontFamily: 'paciencia',
      // backgroundColor: 'red',
      // minHeight: '2.5rem',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      // backgroundColor: state.isSelected ? 'white' : 'transparent',
      // minHeight: '2.5rem',
      fontSize: '12px',
      lineHeight: '36px',
      fontFamily: 'paciencia',
      paddingInline: '0',
      paddingLeft: '7px',
      paddingTop: '1px',
    }),
    valueContainer: (state) => {
      return {
        height: '36px',
        fontSize: '12px',
        lineHeight: '1.2',
        display: 'flex',
        fontFamily: 'paciencia',
        color: state.WebkitOverflowScrolling === 'touch' ? '#A8A8A8' : '#100c08',
        padding: '0',
        flex: 'unset',
      };
    },
    indicatorsContainer: () => ({
      width: 'fit-content',
    }),
    placeholder: () => ({
      lineHeight: '36px',
      color: '#A8A8A8',
      paddingBottom: '10px',
      paddingLeft: '7px',
      fontFamily: 'paciencia',
      top: '8%',
      // minHeight: '2.5rem',
      fontSize: '14px',
    }),
  };

  const [stringValue, setStringValue] = useState('undefined');

  const handleSelectChange = (e: SelectVal) => {
    // console.log('handleSelectChange ', stringValue);
    input.onChange;
    input.onChange(e?.value);
    // onChange(e?.value);
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = e?.value;
    }
  };

  // const handleSelectChange = (selectedOption: Option) => {
  //   console.log('handleSelectChange ');
  //   input.onChange(selectedOption.value);
  //   if (hiddenInputRef.current) {
  //     hiddenInputRef.current.value = e?.value;
  //   }
  // };

  const selectedOption = options.find((option) => option.value === input.value);

  // console.log('I m here');
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <label>{required ? `${label}*` : label}</label>
      </div>

      <Select
        {...rest}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        options={
          label === 'Preferred language' || label === 'Idioma preferido'
            ? filteredOptions
            : options
            ? options
            : []
        }
        // options={options}
        isSearchable={false}
        placeholder={placeholder}
        styles={customStyles}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        onChange={(e: SelectVal) => {
          input.onChange(e?.value);
          // handleSelectChange;
          setStringValue(e?.value);
          // input.onChange(selectedOption.value);
          //  console.log('selectedOption  input.name');
          // const { name, value }  = ;
        }}
        // onChange={onChange}
        // onChangeCapture={onChange}
        value={selectedOption}
      />
      <div
        className={styles.hiddenField}
        data-error={Boolean((meta.error || meta.submitError) && meta.touched)}
      >
        <input
          {...input}
          {...rest}
          ref={hiddenInputRef}
          type="string"
          name={input.name}
          onChange={input.onChange}
          value={stringValue}
        />
      </div>
    </div>
  );
};

// return (
//   <div className={styles.wrapper}>
//     <div className={styles.label}>
//       <label>{required ? `${label}*` : label}</label>
//     </div>
//     <div data-error={Boolean((meta.error || meta.submitError) && meta.touched)}>
//       <Select
//         {...input}
//         {...rest}
//         components={{ DropdownIndicator, IndicatorSeparator: () => null }}
//         options={options}
//         isSearchable={false}
//         placeholder={placeholder}
//         styles={customStyles}
//         onMenuOpen={() => setMenuOpen(true)}
//         onMenuClose={() => setMenuOpen(false)}
//         value={input.value}
//         onChange={input.onChange}
//       />
//       {/* {(meta.error || meta.submitError) && meta.touched && (
//         <span>{meta.error || meta.submitError}</span>
//       )} */}
//     </div>
//   </div>
// );

// onChange={(e: SelectVal) => {
//   input.onChange(e?.value);
//   console.log('e ', e);
//   if (e as SelectVal) {
//     setStringValue(e?.value);
//   }
// }}
// onChange={(e: SelectVal) => {
//   input.onChange(e);
//   console.log('e ', e);
//   if (e as SelectVal) {
//     setStringValue(e?.value);
//     setSelectValue(e?.value);
//   }
// }}
// value={input.value}
