export interface emailLeadInput {
  email: string;
  first_name: string;
  last_name: string;
  address1: string;
  city: string;
  zipcode: number;
  country: string;
  url: string;
  campaign: string;
  code: string;
  search_term: string;
}

export const emailLead = async (input: any) => {
  try {
    const response = await fetch(`/.netlify/functions/emailLead`, {
      method: 'POST',
      body: JSON.stringify(input),
    });

    return await response.json();
  } catch (error) {
    // return {
    //   statusCode: error.response.data.title.status,
    //   body: JSON.stringify(error.response.data.title),
    // };
  }
};
