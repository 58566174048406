import { useCallback, useLayoutEffect } from 'react';

function useScrollLock() {
  const lockScroll = useCallback(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `var(--scrollbar-width)`;
    document.body.dataset.scrollLock = 'true';

    //     if (isiOS) {
    //       scrollOffset.current = window.pageYOffset;
    //       document.body.style.position = "fixed";
    //       document.body.style.top = `-${scrollOffset.current}px`;
    //       document.body.style.width = "100%";
    //     }
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';

    //     if (isiOS) {
    //       document.body.style.position = "";
    //       document.body.style.top = ``;
    //       document.body.style.width = "";
    //       window.scrollTo(0, scrollOffset.current);
    //     }
    delete document.body.dataset.scrollLock;
  }, []);

  const isWindow = typeof window !== 'undefined';
  const isDocument = typeof document !== 'undefined';

  useLayoutEffect(() => {
    if (isWindow && isDocument) {
      const scrollBarWidth = window.innerWidth - document.body.clientWidth;
      document.body.style.setProperty('--scrollbar-width', `${scrollBarWidth}px`);
    }
  }, []);
  return {
    lockScroll,
    unlockScroll,
  };
}

export default useScrollLock;

/**
 * [data-scroll-lock] .anythingFixedPosition{
 *   margin-right: var(--scrollbar-width);
 * }
 */
