import { useState, useEffect } from 'react';
import { useWindowSize } from './useWindowSize';

import * as s from '../styles/main.module.scss';

export const useGlobalWrapperClass = () => {
  const windowSize = useWindowSize();
  const [wrapperClass, setWrapperClass] = useState(s.wrapper30Px);

  useEffect(() => {
    // Handler to call on window resize
   // console.log(' CLASS CLASSCLASSCLASSCLASSCLASS windowSize.width : ', windowSize.width);

   if (windowSize.width < 400) {
    setWrapperClass(s.wrapper20Px);
  }
    if (windowSize.width >= 400 &&  windowSize.width < 800) {
      setWrapperClass(s.wrapper30Px);
    }
    if (windowSize.width >= 800 &&  windowSize.width < 1040) {
      setWrapperClass(s.wrapper50Px);
    }
    if (windowSize.width >= 1040 && windowSize.width < 1240) {
      setWrapperClass(s.wrapper100Px);
    }
    if (windowSize.width >= 1240 && windowSize.width < 2095) {
      setWrapperClass(s.wrapper200Px);
    }
    if (windowSize.width >= 2095) {
      setWrapperClass(s.wrapper300Px);
    }

    // console.log(
    //   ' CLASSCLASSCLASSCLASSCLASSCLASSCLASSCLASS useGlobalWrapperClass handleResize windowSize.width : ',
    //   windowSize.width,
    // );

    // console.log(
    //   ' CLASSCLASSCLASSCLASSCLASSCLASSCLASSCLASSCLASS useGlobalWrapperClass handleResize wrapperClass : ',
    //   wrapperClass,
    // );
  }, [windowSize]);

  // Empty array ensures that effect is only run on mount
  return wrapperClass;
};
