import React, { useEffect, useState } from 'react';

import * as s from '../../styles/main.module.scss';

import { useWindowSize } from '../../hooks/useWindowSize';
import ArrowUpSvg from 'src/assets/svgs/up_arrow.svg';

import { Spacer } from '../layout/Spacer';

interface LayoutProps {
  // children: ReactNode;
}

export const ArrowUp: React.FC<LayoutProps> = ({}) => {
  const [headerHeightState, setHeaderHeightState] = useState(0);
  const isWindow = typeof window !== 'undefined';
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width >= 750) {
      setHeaderHeightState(92);
    } else {
      setHeaderHeightState(64);
    }
  }, []);

  const goToTop = () => {
    if (isWindow) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div id="ArrowUp" style={{ width: windowSize.width }}>
      <div
        id="BigWordsCarouselArrowBack"
        onClick={() => goToTop()}
        className={`
          ${s.widthAuto}
          ${s.cursorPointer}
          ${s.heightAuto}
          ${s.hoverEffect}
          ${s.svgColorDarkGrey}
          `}
      >
        <ArrowUpSvg className={s.height20Px} />
      </div>
      <Spacer />
    </div>
  );
};
