// extracted by mini-css-extract-plugin
export var bold = "headerDesktop-module--bold--51d43";
export var fake = "headerDesktop-module--fake--3fca1";
export var language = "headerDesktop-module--language--3551b";
export var light = "headerDesktop-module--light--69acf";
export var link = "headerDesktop-module--link--de7c9";
export var menuLink = "headerDesktop-module--menuLink--5d570";
export var selected = "headerDesktop-module--selected--80ceb";
export var subMenu = "headerDesktop-module--subMenu--9f89f";
export var topLink = "headerDesktop-module--topLink--35fea";
export var translateSelf = "headerDesktop-module--translateSelf--67a31";