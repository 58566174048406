import './src/styles/fonts.scss';
import './src/styles/default.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';

import { Layout } from './src/components/layout/Layout';
import { PageContextProvider } from './src/components/context/PageContext';
import { FirebaseContextProvider } from './src/components/context/FirebaseContext';

export const onRenderBody = ({ setPostBodyComponents }) => {
  setPostBodyComponents([
    <script
      key="hubspot"
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src="//js.hs-scripts.com/21307994.js"
    />,
  ]);
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider {...props}>
      <FirebaseContextProvider>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
          <Layout>{element}</Layout>
        </GoogleReCaptchaProvider>
      </FirebaseContextProvider>
    </PageContextProvider>
  );
};

// export const onRenderBody = ({ setPostBodyComponents, setHeadComponents, setBodyAttributes }) => {
//   setHeadComponents([
//     // <link as="script" rel="preload" href="/scripts/preloader.js" />,
//   ]);
//   setPostBodyComponents([
//     <script type="text/javascript" src="https://static.leaddyno.com/js"></script>,

//     <script
//       type="text/javascript"
//       id="hs-script-loader"
//       async
//       defer
//       src="//js.hs-scripts.com/21307994.js"
//     ></script>,
//   ]);
// };
