import React, { ReactNode, useEffect, useLayoutEffect, useState, useRef } from 'react';

import * as s from '../../styles/main.module.scss';

import { usePageContext } from '../context/PageContext';
import { useWindowSize } from '../../hooks/useWindowSize';

interface LayoutProps {
  className?: string;
  // children: ReactNode;
}

export const Spacer: React.FC<LayoutProps> = ({ className }) => {
  const windowSize = useWindowSize();

  return (
    <div
      id="Spacer"
      className={`
      ${className}
      ${s.width100}
      ${s.cursorPointer}
      ${windowSize.width < 740 ? s.height45Px : s.height75Px}
      ${s.backGroundColorWhite}

      `}
    ></div>
  );
};
