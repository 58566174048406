import { useState, useEffect } from 'react';
import { useWindowSize } from './useWindowSize';

export const useGlobalWrapperVal = () => {
  const windowSize = useWindowSize();

  const [wrapperVal, setWrapperVal] = useState(30);

  useEffect(() => {
    // Handler to call on window resize
    // console.log(
    //   'VAL VAL VAL VALVAL VALVAL VAL useGlobalWrapperVal : windowSize.width',
    //   windowSize.width,
    // );
    if (windowSize.width < 400) {
      setWrapperVal(20);
    }
      if (windowSize.width >= 400 &&  windowSize.width < 800) {
        setWrapperVal(30);
      }
      if (windowSize.width >= 800 &&  windowSize.width < 1040) {
        setWrapperVal(50);
      }

    if (windowSize.width >= 1040 && windowSize.width < 1240) {
      setWrapperVal(100);
    }
    if (windowSize.width >= 1240 && windowSize.width < 2095) {
      setWrapperVal(200);
    }
    if (windowSize.width >= 2095) {
      setWrapperVal(300);
    }
    // console.log(
    //   'VAL VALVAL VALVAL VALVAL VALVAL VAL  useGlobalWrapperVal : wrapperValue.width',
    //   wrapperVal,
    // );
  }, []);

  useEffect(() => {
    // Handler to call on window resize
    // console.log(
    //   'VAL VAL VAL VALVAL VALVAL VAL useGlobalWrapperVal : windowSize.width',
    //   windowSize.width,
    // );
    if (windowSize.width < 400) {
      setWrapperVal(20);
    }
      if (windowSize.width >= 400 &&  windowSize.width < 800) {
       
        setWrapperVal(30);
      }
      if (windowSize.width >= 800 &&  windowSize.width < 1040) {
  
        setWrapperVal(50);
      }


    if (windowSize.width >= 1040 && windowSize.width < 1240) {
      setWrapperVal(100);
    }
    if (windowSize.width >= 1240 && windowSize.width < 2095) {
      setWrapperVal(200);
    }
    if (windowSize.width >= 2095) {
      setWrapperVal(300);
    }
    // console.log(
    //   'VAL VALVAL VALVAL VALVAL VALVAL VAL  useGlobalWrapperVal : wrapperValue.width',
    //   wrapperVal,
    // );
  }, [windowSize]); 
  // Empty array ensures that effect is only run on mount
  return wrapperVal;
};
