import React, { useMemo } from 'react';
import * as s from '../../styles/main.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '../context/PageContext';
import { HeaderQuery } from 'graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { localizePath } from '../items/LotCard';
import { Link, navigate } from 'gatsby';
import * as styles from './headerDesktop.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { UseFirebaseContext } from '../context/FirebaseContext';
interface LayoutProps {
  // children: ReactNode;
}

export const HeaderAccount: React.FC<LayoutProps> = ({}) => {
  const fireBaseContext = UseFirebaseContext();
  const data = useStaticQuery<HeaderQuery>(headerQuery);
  const logoSrcDark = data?.allSanityHeaderLogo?.nodes[0]?.logoDark?.asset?.gatsbyImageData;
  const { lang, setLang, originalPath, article } = usePageContext();
  const { setFormPopUpState, setLoginDrawerState, loginDrawerState, setMenuState } =
    usePageContext();
  const ctaText = data?.allSanitySetUpACallButton?.nodes[0]?._rawCtaText?.[lang];
  const backToSiteText = data?.allSanityAccountSettings?.nodes[0]?._rawBacktowebsiteButton?.[lang];

  const openPopup = () => {
    setFormPopUpState({ popupState: 'meeting' });
    setLoginDrawerState({ loginDrawer: false });
    setMenuState({ menuStateOpen: false });
  };

  const openLoginDrawer = () => {
    //console.log('openLoginDrawer ');
    if (loginDrawerState.loginDrawer === false) {
      setLoginDrawerState({ loginDrawer: true });
      setMenuState({ menuStateOpen: false });
      setFormPopUpState({ popupState: 'closed' });
    } else {
      setLoginDrawerState({ loginDrawer: false });
    }
  };

  const isMobileLayout = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window?.innerWidth < 700;
    }
  }, []);

  const insertSwitchLanguage = () => {
    //console.log("////////////////////////////////////insertSwitchLanguage")
    return (
      <div
        // style={{ left: windowSize.width - 80 }}
        id="SwitchLanguageBox"
        className={`
        ${s.flexDirectionRow}
        ${s.height40Px}
        ${s.justifyContentFlexEnd}
        ${s.alignItemsFlexEnd}
        ${s.widthFitContent}
        `}
      >
        <div
          id="SwitchLanguageEnglish"
          onClick={() => {
            if (lang === 'es') {
              setLang('en');
              navigate(originalPath);
            }
          }}
          style={{ lineHeight: 1.5 }}
          className={`
            ${s.width40Px}
            ${s.height40Px}
            ${s.cursorPointer}
            ${s.alignItemsCenter}
            ${s.justifyContentCenter}
            ${lang === 'en' ? s.colorDarkgrey : s.colorClearGrey}
          `}
        >
          <p
            className={` 
          ${s.textTransformUppercase}
          ${s.fontFamilyPoppinsReg}
          ${s.fontSize12Px}
          ${s.letterSpacing1And5Px}
          ${s.textAlignCenter}
          ${lang === 'en' ? styles.selected : ''} ${styles.language} `}
          >
            EN
          </p>
        </div>

        <div
          id="SwitchLanguageSpanish"
          style={{ lineHeight: 1.5 }}
          onClick={() => {
            if (lang === 'en') {
              setLang('es');
              navigate('/es' + originalPath);
            }
          }}
          className={`
          ${s.width40Px}
          ${s.height40Px}
          ${s.cursorPointer}
          ${s.alignItemsCenter}
          ${s.justifyContentCenter}
            ${s.hoverEffect}
            ${lang === 'es' ? s.colorDarkgrey : s.colorClearGrey}
            ${lang === 'es' && s.fontFamilyPoppinsBld}
          `}
        >
          <p
            className={`
          ${s.textTransformUppercase}
          ${s.fontFamilyPoppinsReg}
          ${s.fontSize12Px}
          ${s.letterSpacing1And5Px}
          ${s.textAlignCenter}
          ${lang === 'es' ? styles.selected : ''} ${styles.language}`}
          >
            ES
          </p>
        </div>
      </div>
    );
  };

  const insertLogo = () => {
    return (
      <Link to={localizePath('/', lang)}>
        <div
          id="HeaderLogoBox"
          className={`
   
          ${isMobileLayout ? s.width110Px : s.width131Px}
          ${isMobileLayout ? s.height41Px : s.height45Px}
          ${s.topMinus16Px}
          ${s.flexGrow1}
          `}
        >
          <GatsbyImage
            className={`
            ${isMobileLayout ? s.width110Px : s.width131Px}
          ${isMobileLayout ? s.height41Px : s.height45Px}
   
            `}
            image={logoSrcDark}
            objectFit="contain"
            alt="background"
          />
        </div>
      </Link>
    );
  };

  return (
    <div
      id="HeaderAccount"
      className={`
      ${s.width100}
      ${s.heightAuto}
      ${s.positionFixed}
      ${s.zIndex1000}
    `}
    >
      <div
        id="HeaderContentRow"
        className={`
        ${s.width100}
        ${s.heightAuto}
        ${s.flexDirectionRow}
        ${s.justifyContentFlexEnd}
        ${s.positionAbsolute}
        ${s.zIndex2}
    `}
      >
        <div id="Menu">
          <div
            id="HeaderDesktopBox"
            className={`
     
          ${isMobileLayout ? s.height95Px : s.height120Px}

          ${s.widthAuto}
           `}
          >
            {/* {article || (scrollState === false && <div className={s.navbarBg} />)} */}
            {/* <div
              id="SetUpACallStripe"
              onClick={() => openPopup()}
              className={`
              ${s.height30Px}
              ${s.backGroundColorDarkgreen}
              ${s.flexDirectionRow}
              ${s.alignItemsCenter}
              ${s.justifyContentFlexEnd}
              ${s.marginLeftAuto}
              ${s.paddingRight20Px}
              ${s.cursorPointer}
            `}
            >
              <img
                className={`${s.width15Px} ${s.height15Px} ${s.marginRight10Px}`}
                src="/svgs/icon_phone.svg"
                alt="icon-phone"
              />
              <p
                className={`
                ${s.widthFitContent}
                ${s.textAlignRight}
                ${s.textTransformUppercase}
                ${s.fontSize12Px}
                ${s.lineHeight1And5}
                ${s.letterSpacing1And5Px}
                ${s.alignItemsCenter}
                ${s.height20Px}
                ${s.fontFamilyPoppinsReg}
                ${s.colorWhite}
                `}
              >
                {ctaText}
              </p>
            </div> */}

            <div
              id="HeaderAccount"
              className={`
             
              ${isMobileLayout ? s.height65Px : s.height75Px}
              ${s.width100}
              ${s.backGroundColorWhite}
              ${isMobileLayout ? s.wrapper20Px : s.wrapper30Px}
            `}
            >
              <div
                id="HeaderAccountRow"
                className={`
                ${s.width100}
                ${isMobileLayout ? s.height65Px : s.height75Px}
                ${s.flexDirectionRow}
                ${s.justifyContentFlexEnd}
                ${s.alignItemsFlexEnd}
               `}
              >
                <div id="LogoBox" className={`${s.flexGrow1}`}>
                  {insertLogo()}
                </div>
                <div
                  id="LinksBox"
                  className={`
                     ${s.widthFitContent}
                    ${s.justifyContentFlexEnd}
                    ${s.alignItemsFlexEnd}
                    ${s.height40Px}
               
                    ${isMobileLayout ? s.gap10Px : s.gap30Px}
                  `}
                >
                  <div
                    id="BackToWebsite"
                    style={{ lineHeight: 1.5 }}
                    onClick={() => {
                      navigate('/');
                    }}
                    className={`
                     ${s.widthFitContent}
                    ${s.justifyContentFlexEnd}
                    ${s.alignItemsFlexEnd}
                    ${s.height40Px}
                    ${s.cursorPointer}
                    ${s.alignItemsCenter}
                    ${s.hoverEffect}
                  `}
                  >
                    <p
                      className={`
                    ${s.widthAuto}
                    ${s.whiteSpaceNowrap}
                      ${s.fontFamilyPoppinsReg}
                      ${s.colorDarkgrey}
                      ${s.fontSize12Px}
                      ${s.letterSpacing1And5Px}
                      ${s.fontFamilyPoppinsReg}
                      ${s.textTransformUppercase}
                      ${styles.language}`}
                    >
                      {backToSiteText}
                    </p>
                  </div>

                  <div
                    id="account"
                    onClick={() => {
                      openLoginDrawer();
                    }}
                    style={{ minWidth: 40 }}
                    className={`
      
                    ${s.flexDirectionRow}
                    ${s.width40Px}
                    ${s.height40Px}
                    ${s.backGroundColorClear}
                    ${s.cursorPointer}
                    ${s.alignItemsCenter}
                    ${s.justifyContentCenter}
                    ${s.colorDarkgreen}
                    ${s.objectFitContain}
                    ${s.fontSize16Px}
                  `}
                  >
                    <FontAwesomeIcon icon={faUser as any} color={'#384735'} />
                  </div>
                  {insertSwitchLanguage()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const headerQuery = graphql`
  query headerAccount {
    allSanitySetUpACallButton {
      nodes {
        id
        _rawCtaText
      }
    }

    allSanityAccountSettings {
      nodes {
        _rawBacktowebsiteButton
      }
    }

    allSanityHeaderLogo {
      nodes {
        logoWhite {
          asset {
            gatsbyImageData(placeholder: NONE)
          }
        }

        logoDark {
          asset {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  }
`;

// ${windowSize.width >= 750 ? s.paddingTopBottom30Px : s.paddingTopBottom16Px}
//${windowSize.width >= 750 ? s.wrapper40Px : s.wrapper20Px}
