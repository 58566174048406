import React, { ReactNode, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import * as s from '../../styles/main.module.scss';

import { graphql, useStaticQuery } from 'gatsby';

import { MainMenuQuery, SanityHeaderLogoConnection } from 'graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { gsap } from 'gsap';
import { usePageContext } from '../context/PageContext';
import { useWindowSize } from '../../hooks/useWindowSize';
import { localizePath } from '../items/LotCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
interface LayoutProps {
  // children: ReactNode;
}

export const MainMenuPop: React.FC<LayoutProps> = ({}) => {
  const StripeTop = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const data = useStaticQuery<MainMenuQuery>(mainMenuQuery);
  let formerPath = '';
  const { menuState, setMenuState } = usePageContext();
  const { lang, setLang, originalPath } = usePageContext();
  const { setLoginDrawerState, loginDrawerState, setFormPopUpState } = usePageContext();

  const SecondLevelItemsBox = useRef<HTMLDivElement>(null);
  const [displayAnySecondLevel, setDisplayAnySecondLevel] = useState('none');
  // ATTENTION A VERIFIER//

  useEffect(() => {
    setMenuState({ menuStateOpen: false });
  }, []);

  useEffect(() => {
    if (menuState.menuStateOpen === true) {
      const StripeTopCurrent = StripeTop.current;
      const animOpenMenu1 = gsap.to(StripeTopCurrent, {
        opacity: 0,
        y: 20,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });

      return () => {
        animOpenMenu1.kill();
      };
    } else {
      setDisplayAnySecondLevel('none');
      const StripeTopCurrent = StripeTop.current;

      const animOpenMenu1 = gsap.to(StripeTopCurrent, {
        opacity: 1,
        y: 0,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });

      return () => {
        animOpenMenu1.kill();
      };
    }
  }, [menuState.menuStateOpen]);

  const openLoginDrawer = () => {
    // console.log('openLoginDrawer ');
    if (loginDrawerState.loginDrawer === false) {
      setLoginDrawerState({ loginDrawer: true });
      setFormPopUpState({ popupState: 'closed' });
      setMenuState({ menuStateOpen: false });
    } else {
      setLoginDrawerState({ loginDrawer: false });
    }
  };

  const closeMenu = () => {
    setMenuState({ menuStateOpen: false });
    setDisplayAnySecondLevel('none');
  };
  // useEffect(() => {
  // }, [lang]);

  const pagesList = data.allSanityDynamicPage.nodes;
  const ecovillasList = data.allSanityEcoVilla.nodes;

  const handleClickFirstLevel = (slug) => {
    // console.log('handleClickFirstLevel', slug);
    if (displayAnySecondLevel !== slug) {
      // console.log('displayAnySecondLevel !== slug', displayAnySecondLevel, slug);
      setDisplayAnySecondLevel(slug);
    } else {
      // console.log('none', displayAnySecondLevel, slug);
      setDisplayAnySecondLevel('none');
    }
  };

  useEffect(() => {
    //  console.log('originalPath ', originalPath);
    closeMenu();
  }, [originalPath]);

  const insertEcovillasLink = ecovillasList?.map((ecovilla, index) => {
    const ecovillaName = ecovilla.name;
    const ecovillaSlug = 'ecovillas' + '/' + ecovilla.slug?.current;
    //console.log("insertEcovillasLink : ",ecovilla.id)
    return (
      <Link
        key={ecovilla.id}
        to={localizePath('/' + ecovillaSlug ?? '', lang)}
        activeClassName={`${s.fontFamilyPoppinsBld}`}
      >
        <div
          key={ecovilla.id}
          id="EcovillaItem"
          className={`
          ${s.textAlignLeft}
          ${s.whiteSpaceNowrap}
          ${s.fontFamilyPaciencia}
          ${s.fontSize18Px}
          ${s.colorWhite}
          ${s.lineHeight1And5}
          ${s.letterSpacing1And5Px}
          ${s.marginBottom10Px}
                `}
        >
          {ecovillaName}
        </div>
      </Link>
    );
  });

  const insertSecondLevelLinks = (parentSlug, itemList) => {
    if (displayAnySecondLevel === parentSlug) {
    }
    const items = itemList?.map((item, index) => {
      const itemName = item?._rawPageTitleMenu?.[lang];
      const ecovillaSlug = parentSlug + '/' + item.slug?.current;
      //console.log("insertSecondLevelLinks : ",item.id)
      return (
        <Link
          key={item.id}
          to={localizePath('/' + ecovillaSlug ?? '', lang)}
          activeClassName={`${s.fontFamilyPoppinsBld}`}
        >
          <div
            key={item.id}
            id="SecondLevelItem"
            className={`
                ${s.textAlignLeft}
                ${s.whiteSpaceNowrap}
                ${s.fontFamilyPaciencia}
                ${s.fontSize18Px}
                ${s.colorWhite}
                ${s.lineHeight1And5}
                ${s.letterSpacing1And5Px}
                ${s.marginBottom10Px}
             
                `}
          >
            {itemName}
          </div>
        </Link>
      );
    });
    return (
      <div
        id="SecondLevelItemsBox"
        ref={SecondLevelItemsBox}
        className={`
      ${s.widthAuto}
      ${s.flexDirectionColumn}
      ${s.justifyContentFlexStart}

      ${s.wrapper20Px}
      ${s.paddingBottom10Px}

      ${displayAnySecondLevel === parentSlug ? s.visibilityVisible : s.visibilityHidden}
      ${displayAnySecondLevel === parentSlug ? s.heightAuto : s.height0Px}
     `}
      >
        {items}
        {parentSlug === 'ecovillas' ? insertEcovillasLink : null}
      </div>
    );
  };

  const insertMenuLink = pagesList?.map((item, index) => {
    const activeInMenu = item?.activeInMainMenu;

    const subdirectory = item?.pageSubdirectory?.slug?.current;
    let childs = false;
    const slug = item?.slug?.current as string;
    let linkToVal = '/' + slug ?? '';
    if (item?.childPagesReferenceList && item?.childPagesReferenceList.length > 0) {
      linkToVal = '';
      childs = true;
    }

    const pageTitle = item?._rawPageTitleMenu?.[lang];

    if (activeInMenu === true) {
      // console.log("insertMenuLink : ",item.id)
      if (item?.childPagesReferenceList && item?.childPagesReferenceList.length > 0) {
        return (
          <div key={item.id}>
            <div
              onClick={() => handleClickFirstLevel(slug)}
              id="MenuItem"
              className={`
              ${s.cursorPointer}
              ${s.textAlignLeft}
              ${s.fontFamilyPaciencia}
              ${windowSize.height < 640 || windowSize.width < 640 ? s.fontSize22Px : s.fontSize36Px}
              ${s.colorWhite}
              ${s.lineHeight1And2}
              ${s.flexDirectionRow}
              ${windowSize.height < 540 ? s.marginTopBottom6Px : s.marginTopBottom10Px}
             `}
            >
              {pageTitle}
              <div
                id="arrowOpenDrawer"
                className={`
          ${s.width18Px}
          ${s.height8Px}
          ${s.marginLeftAuto}
          ${childs ? s.displayBlock : s.displayNone}
          `}
              >
                <img
                  className={`${s.width18Px} ${s.height8Px}${s.arrow}      ${
                    s.transformOriginCenter
                  }
          ${displayAnySecondLevel === slug ? s.transformRotation180 : s.transformRotation0} `}
                  src="/svgs/fleche.svg"
                  alt="SVG as an image"
                />
              </div>
            </div>
            {item?.childPagesReferenceList && item?.childPagesReferenceList.length > 0
              ? insertSecondLevelLinks(slug, item?.childPagesReferenceList)
              : null}
          </div>
        );
      } else {
        return (
          <Link key={item.id} to={localizePath(linkToVal, lang)}>
            <div
              onClick={() => handleClickFirstLevel(slug)}
              id="MenuItem"
              className={`
              ${s.textAlignLeft}
              ${s.fontFamilyPaciencia}
              ${windowSize.height < 640 || windowSize.width < 640 ? s.fontSize22Px : s.fontSize36Px}
              ${s.colorWhite}
              ${s.lineHeight1And2}
              ${s.flexDirectionRow}
              ${windowSize.height < 540 ? s.marginTopBottom6Px : s.marginTopBottom10Px}`}
            >
              {pageTitle}
              <div
                id="arrowOpenDrawer"
                className={`
          ${s.width18Px}
          ${s.height8Px}
          ${s.marginLeftAuto}
          ${childs ? s.displayBlock : s.displayNone}
          `}
              >
                <img
                  className={`${s.width18Px} ${s.height8Px}${s.arrow}      ${
                    s.transformOriginCenter
                  }
          ${displayAnySecondLevel === slug ? s.transformRotation180 : s.transformRotation0} `}
                  src="/svgs/fleche.svg"
                  alt="SVG as an image"
                />
              </div>
            </div>
            {item?.childPagesReferenceList && item?.childPagesReferenceList.length > 0
              ? insertSecondLevelLinks(slug, item?.childPagesReferenceList)
              : null}
          </Link>
        );
      }
    } else return <div key={item.id}></div>;
  });

  // const insertEcovillasLink = ecovillasList?.map((ecovilla, index) => {
  //   const ecovillaName = ecovilla.name;
  //   const ecovillaSlug = 'ecovillas' + '/' + ecovilla.slug?.current;
  //   return (
  //     <Link key={ecovilla.id} to={localizePath('/' + ecovillaSlug ?? '', lang)}>
  //       <div
  //         id="EcovillaItem"
  //         onClick={() => closeMenu()}
  //         className={`
  //         ${s.textAlignLeft}
  //         ${s.fontFamilyPaciencia}
  //         ${windowSize.width < 400 ? s.fontSize18Px : s.fontSize22Px}
  //         ${s.colorWhite}
  //         ${s.lineHeight1And2}
  //         ${s.marginTop16Px}
  //         ${s.marginLeft20Px}
  //        `}
  //       >
  //         {ecovillaName}
  //       </div>
  //     </Link>
  //   );
  // });

  // return (
  //   <div
  //     key={item.id}
  //     id="MenuItemEcovillas"
  //     className={`
  //     ${s.textAlignLeft}
  //     ${s.fontFamilyPaciencia}
  //     ${windowSize.width < 400 ? s.fontSize22Px : s.fontSize36Px}
  //     ${s.colorWhite}
  //     ${s.lineHeight1And2}
  //     ${s.marginTopBottom10Px}
  //    `}
  //   >
  //     <Link to={localizePath('/' + slug ?? '', lang)}>
  //       {' '}
  //       <div>{pageTitle}</div>
  //     </Link>
  //     <div>{insertEcovillasLink}</div>
  //   </div>
  // );

  /////////////COMPONENT RENDER///////////////////////////////////////////////////////
  return (
    <div
      id="MainMenPop"
      style={{ width: windowSize.width }}
      className={`
      ${s.height100}
      ${s.positionFixed}
      ${s.zIndex900}
      ${s.backGroundColorDarkgreen}
      ${s.justifyContentCenter}
      ${s.alignItemsCenter}
      ${s.flexDirectionColumn}
      ${menuState.menuStateOpen === true ? s.visibilityVisible : s.visibilityHidden}
      `}
    >
      <div
        id="MainMenuLinksBox"
        style={{ width: windowSize.width }}
        className={`
       ${s.heightAuto}
        ${s.positionFixed}
        ${s.top94Px}
        ${s.zIndex900}
        ${s.paddingTop20Px}
        ${s.alignItemsCenter}
        ${s.flexDirectionColumn}
        ${menuState.menuStateOpen === true ? s.visibilityVisible : s.visibilityHidden}
       `}
      >
        <div
          id="MainMenuLinksBox"
          className={`
          ${s.width100}
          ${s.heightAuto} 
          ${s.wrapper30Px}
          ${s.flexDirectionColumn}
          ${menuState.menuStateOpen === true ? s.visibilityVisible : s.visibilityHidden}
      `}
        >
          {insertMenuLink}
        </div>
      </div>
      <div
        id="SwitchLanguageBox"
        className={`
         ${s.width100}
        ${s.heightAuto}
        ${s.flexDirectionRow}
        ${s.marginTopAuto}
        ${s.marginBottom0}
        ${s.backGroundColorDarkgreen}
        ${s.zIndex1000}
        ${s.justifyContentCenter}
    `}
      >
        <div
          id="account"
          onClick={() => {
            openLoginDrawer();
          }}
          style={{ minWidth: 40 }}
          className={`
      
                    ${s.flexDirectionRow}
                    ${s.width40Px}
                    ${s.height40Px}
                    ${s.backGroundColorClear}
                    ${s.cursorPointer}
                    ${s.alignItemsCenter}
                    ${s.justifyContentCenter}
                    ${s.colorDarkgreen}
                    ${s.objectFitContain}
                    ${s.fontSize16Px}
                  `}
        >
          <FontAwesomeIcon icon={faUser as any} color={'#ffffff'} />
        </div>
        <div
          id="SwitchLanguageEnglish"
          onClick={() => {
            if (lang === 'es') {
              setLang('en');
              navigate(originalPath);
            }
          }}
          className={`
          ${s.widthAuto}
          ${s.heightAuto}
          ${s.textTransformUppercase}
          ${s.fontFamilyPoppinsReg}
          ${s.fontSize12Px}
          ${s.letterSpacing1And5Px}
          ${s.wrapper20Px}
          ${s.cursorPointer}
          ${lang === 'en' ? s.colorWhite : s.colorClearGrey}
          ${s.justifyContentCenter}
          ${s.alignItemsCenter}
          `}
        >
          English
        </div>

        <div
          id="SwitchLanguageSpanish"
          onClick={() => {
            if (lang === 'en') {
              setLang('es');
              navigate('/es' + originalPath);
            }
          }}
          className={`
          ${s.textTransformUppercase}
          ${s.widthAuto}
          ${s.heightAuto}
          ${s.cursorPointer}
          ${s.fontFamilyPoppinsReg}
          ${s.fontSize12Px}
          ${s.letterSpacing1And5Px}
          ${s.wrapper20Px}
          ${s.justifyContentCenter}
          ${s.alignItemsCenter}
          ${lang === 'es' ? s.colorWhite : s.colorClearGrey}
          `}
        >
          ESPAÑOL
        </div>
      </div>
    </div>
  );
};

export const mainMenuQuery = graphql`
  query mainMenu {
    allSanityDynamicPage(sort: { orderRank: ASC }) {
      nodes {
        id
        pageTitle
        _rawPageTitleMenu
        orderRank
        slug {
          current
        }
        childPagesReferenceList {
          id
          pageTitle
          _rawPageTitleMenu
          slug {
            current
          }
        }
        pageSubdirectory {
          slug {
            current
          }
        }
        activeInMainMenu
      }
    }
    allSanityEcoVilla(sort: { orderRank: ASC }) {
      nodes {
        id
        slug {
          current
        }
        name
      }
    }
  }
`;
