import * as s from '../../styles/main.module.scss';

import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { AccountHeaderMenu } from './AccountHeaderMenu';
import { AccountSideMenu } from './AccountSideMenu';
import { ArrowUp } from './ArrowUp';
import { Footer } from './Footer';
import { FormPop } from './FormPop';
import { Header } from './Header';
import { HeaderAccount } from './HeaderAccount';
import { HeaderSpacer } from './HeaderSpacer';
import { LoginDrawer } from './LoginDrawer';
import { MainMenuPop } from './MainMenuPop';
import { NewsletterForm } from './NewsletterForm';
import { Spacer } from './Spacer';
import { UseFirebaseContext } from '../context/FirebaseContext';
import { navigate } from 'gatsby';
import { usePageContext } from '../context/PageContext';
import useScrollLock from '../../hooks/useScrollLock';
import { useWindowSize } from '../../hooks/useWindowSize';

declare global {
  interface Window {
    hsConversationsSettings: any;
    HubSpotConversations: any;
    hsConversationsOnReady: any;
    LeadDyno: any;
  }
}

interface LayoutProps {
  children: ReactNode;
}

export const Layout: React.FC<LayoutProps> = (props: any) => {
  const {
    setWindowState,
    setCampaign,
    setAfmc,
    originalPath,
    fullScreen,
    headerState,
    formPopUpState,
    loginDrawerState,
    setLoginDrawerState,
  } = usePageContext();

  const windowSize = useWindowSize();

  const isBrowser = typeof window !== 'undefined';

  const fireBaseContext = UseFirebaseContext();
  if (fireBaseContext !== null) {
    const { user } = fireBaseContext;
  }

  const [isUserLogged, setIsUserLogged] = useState(false);
  // const [path, setPath] = useState(typeof location !== 'undefined' ?  location?.pathname : "/");

  useEffect(() => {
    if (
      fireBaseContext?.user &&
      fireBaseContext?.user !== null &&
      fireBaseContext?.userInfo !== null
    ) {
      setIsUserLogged(true);
    } else {
      setIsUserLogged(false);
    }
  }, [fireBaseContext?.user, fireBaseContext?.userInfo]);

  const location = props?.children?.props.location;
  const { unlockScroll } = useScrollLock();

  const isMobileLayout = useMemo(() => {
    if (isBrowser) {
      return window?.innerWidth < 700;
    }
  }, [windowSize]);

  const resizeValues = () => {
    if (isBrowser) {
      var browserZoomLevel = Math.round(window.devicePixelRatio * 100);

      let vw = window?.innerWidth; //* 0.01;
      let vh = window?.innerHeight; //* 0.01;

      setWindowState({
        width: window?.innerWidth,
        height: window?.innerHeight,
      });
    }
  };

  // const isAnAccountPage = useMemo(() => {
  //   if (isBrowser) {
  //     const isAccountInURL = location?.pathname?.includes('/account/');
  //     return isAccountInURL;
  //   } else {
  //     return false;
  //   }
  // }, [isBrowser]);

  useEffect(() => {
    setLoginDrawerState({ loginDrawer: false });
  }, [location?.pathname]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const campaign = params.get('utm_campaign');
    const afmc = params.get('afmc');
    if ((campaign?.length ?? 0) > 0) setCampaign(campaign as string);
    if ((afmc?.length ?? 0) > 0) setAfmc(afmc as string);
  }, [location?.search]);

  useEffect(() => {
    resizeValues();
    if (isBrowser) {
      if (window.LeadDyno) {
        //console.log('LeadDyno loaded', window.LeadDyno);
        window.LeadDyno.domain = 'laecovilla.com';
        window.LeadDyno.key = '8a2f639ce73d0886c48443993d0e5f05ace93863';
        window.LeadDyno.recordVisit();
        // console.log('LeadDyno recordVisit');
        window.LeadDyno.autoWatch();
      }
    }
    // console.log('LeadDyno autoWatch');

    function onConversationsAPIReady() {}
    /*
      configure window.hsConversationsSettings if needed.
    */
    if (isBrowser) {
      window.hsConversationsSettings = {};

      /*
     If external API methods are already available, use them.
    */

      if (window.HubSpotConversations) {
        onConversationsAPIReady();
      } else {
        /*
        Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
        These callbacks will be called once the external API has been initialized.
      */
        // window.hsConversationsOnReady = [onConversationsAPIReady];
      }

      window.hsConversationsSettings = {
        loadImmediately: true,
        // inlineEmbedSelector: '#chat-test',//
        enableWidgetCookieBanner: true,
        disableAttachment: true,
      };
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.widget.load();
        },
      ];
      window.addEventListener('resize', () => {
        resizeValues();
      });

      return () => window.removeEventListener('resize', () => {});
    }
  }, []);

  useEffect(() => {
    if (isBrowser) {
      unlockScroll();
    }
  }, [originalPath]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      if (fullScreen) document?.documentElement.classList.add('hiddenHubspot');
      if (!fullScreen) document?.documentElement?.classList.remove('hiddenHubspot');
    }
  }, [fullScreen]);

  const currentHeader =
    typeof document !== 'undefined'
      ? (document?.getElementById(`HeaderContentRow`) as HTMLElement)
      : null;

  const headerHeight = useMemo(() => {
    const headerCurrentHeight = currentHeader?.offsetHeight;
    return headerCurrentHeight;
  }, [currentHeader?.offsetHeight]);

  const [isAnAccountPage, setIsAnAccountPage] = useState(false);

  useEffect(() => {
    if (isBrowser) {
      const isAccountInURL = location?.pathname?.includes('/account/');
      setIsAnAccountPage(isAccountInURL);
    } else {
      setIsAnAccountPage(false);
    }
  }, [isBrowser, location?.pathname]);

  const windoSize = useWindowSize();

  return (
    <div id="Layout" className={` ${s.width100Height100} ${fullScreen && s.fullScreen}`}>
      <>
        {formPopUpState.popupState !== 'closed' && <FormPop />}

        {headerState.headerStateVisible === true && !isAnAccountPage && (
          <>
            <Header />
            <MainMenuPop />
            <HeaderSpacer />
          </>
        )}
        {loginDrawerState.loginDrawer === true && (
          <>
            <LoginDrawer />
          </>
        )}

        {isAnAccountPage && isUserLogged && (
          <>
            <HeaderAccount />
            <div
              style={{
                width: '100%',
                height: headerHeight && headerHeight > 0 ? headerHeight : 120,
              }}
            />
            {isMobileLayout && <AccountHeaderMenu />}
            <div className={`${s.width100} ${s.flexDirectionRow}`}>
              {!isMobileLayout && <AccountSideMenu />}
              <main
                style={{
                  maxWidth: !isMobileLayout ? windoSize.width - 300 : windoSize.width,
                  width: !isMobileLayout ? windoSize.width - 300 : windoSize.width,
                }}
              >
                {props.children}
              </main>
            </div>
            <Spacer />
          </>
        )}

        {isAnAccountPage && !isUserLogged && navigate('/')}

        {!isAnAccountPage && <main>{props.children}</main>}
        <ArrowUp />
        <NewsletterForm />
        <Footer />
      </>
    </div>
  );
};
