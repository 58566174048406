import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '../context/PageContext';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as s from '../../styles/main.module.scss';
import { ButtonMore } from '../items/ButtonMore';
import { NewsLetterFormQuery, SanityButton, SanityNewsLetterBlockConnection } from 'graphql-types';
import { useWindowSize } from 'src/hooks/useWindowSize';

interface LayoutProps {
  // children: ReactNode;
}

export const NewsletterForm: React.FC<LayoutProps> = ({}) => {
  const data = useStaticQuery<NewsLetterFormQuery>(newsLetterFormQuery);
  const windowSize = useWindowSize();
  const { lang } = usePageContext();
  const backGroundImage = data?.allSanityNewsLetterBlock?.nodes[0]?.image?.asset?.gatsbyImageData;
  const sentence = data.allSanityNewsLetterBlock?.nodes[0]?._rawShortText?.[lang];
  const button = data.allSanityNewsLetterBlock?.nodes[0]?.button as SanityButton;
  //const ctaText = data.allSanityNewsLetterBlock?.nodes[0]._rawCta[lang];
  return (
    <div
      id="NewsletterForm"
      className={`
      ${s.width100}
      ${s.height300Px}
   
      ${s.justifyContentCenter}
      ${s.alignItemsCenter}
      `}
    >
      <div
        id="NewsletterContentBlock"
        className={`
        ${s.height300Px}
        ${s.width100}
        ${s.positionAbsolute}
        ${s.zIndex10}
        ${s.wrapper20Px}
        ${s.justifyContentCenter}
        ${s.alignItemsCenter}
        ${s.flexDirectionColumn}
        `}
      >
        <div
          id="NewsletterShortText"
          className={`
          ${s.heightAuto}
          ${s.width100}
           `}
        >
          <p
            className={`
            ${s.whiteSpacePreWrap}
            ${s.textAlignCenter}
            ${s.colorWhite}
            ${windowSize.width < 740 ? s.fontSize24Px : s.fontSize36Px}
            ${s.fontFamilyPaciencia}
            ${s.lineHeight1And2}
            ${s.letterSpacing0And4Px}
           `}
          >
            {sentence}
          </p>
        </div>
        <div
          id="NewsletterShortText"
          className={`
          ${s.heightAuto}
 
          ${s.justifyContentCenter}
          ${s.alignItemsCenter}
           `}
        >
          <ButtonMore color="white" button={button} />
        </div>
      </div>
      <div
        id="NewsletterBackgroundImage"
        className={`
        ${s.height300Px}
      ${s.backGroundColorBlack}
      ${s.width100}
      
      `}
      >
        <GatsbyImage
          className={`
          ${s.height300Px}
          ${s.opacity05}
          ${s.width100}
          ${s.marginAuto}
          `}
          objectFit="cover"
          image={backGroundImage}
          alt="background"
        />
      </div>
    </div>
  );
};

export const newsLetterFormQuery = graphql`
  query newsLetterForm {
    allSanityNewsLetterBlock {
      nodes {
        image {
          asset {
            gatsbyImageData(aspectRatio: 3)
          }
        }
        button {
          _rawCta
          externalLink
          formLink
          formBlockRef {
            formSelector
          }
          siteLink {
            ... on SanityDynamicPage {
              id
              slug {
                current
              }
            }
            ... on SanityEcoVilla {
              id
              name
              slug {
                current
              }
            }
            ... on SanityLot {
              id
              slug {
                current
              }
            }
          }
        }
        _rawShortText
      }
    }
  }
`;
