import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { components } from 'react-select';
import TimezoneSelect from 'react-timezone-select';
import UpArrow from '../../../assets/svgs/fleche.svg';
import * as styles from './timeZoneField.module.scss';

export const TimeZoneField = ({
  input,
  meta,
  required,
  label,
  fieldType,
  placeholder,
  children,
}: FieldRenderProps<string, HTMLElement>) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const DropdownIndicator = (props: DropdownIndicatorProps<true>) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className={styles.indicator}>
          <UpArrow className={menuOpen ? styles.iconDown : styles.iconUp} />
        </div>
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      fontSize: '12px',
      lineHeight: '1.2',
      color: state.isFocused ? '#100c08' : '#A8A8A8',
      fontWeight: 400,
      cursor: 'pointer',
      paddingLeft: 20,
    }),
    container: () => ({
      //       display: 'flex',
      //       flexDirection: 1,
      gap: '6.25px',
      height: '38px',
      borderRadius: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'no-wrap',
      cursor: 'pointer',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #D6D6D6',
      padding: '0 10px',
      color: 'black',
      backgroundColor: menuOpen ? 'white' : 'transparent',
      borderRadius: 0,
    }),

    menu: () => ({
      backgroundColor: 'transparent',
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      left: '0',
      top: '40px',

      border: 'unset',
    }),
    menuList: (base) => ({
      ...base,
      border: '1px solid #D6D6D6',
      paddingLeft: '0',
      fontFamily: 'paciencia',
      //       maxHeight: '200px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      // backgroundColor: state.isSelected ? 'white' : 'transparent',
      // minHeight: '2.5rem',
      fontSize: '12px',
      lineHeight: '36px',
      fontFamily: 'paciencia',
      paddingInline: '0',
    }),
    valueContainer: (state) => {
      return {
        height: '36px',
        fontSize: '12px',
        lineHeight: '1.2',
        display: 'flex',
        fontFamily: 'paciencia',
        color: state.WebkitOverflowScrolling === 'touch' ? '#A8A8A8' : '#100c08',
        padding: '0',
      };
    },
    indicatorsContainer: () => ({
      width: 'fit-content',
    }),
    placeholder: () => ({
      lineHeight: '36px',
      color: '#A8A8A8',
      paddingBottom: '10px',
      paddingLeft: '7px',
      fontFamily: 'paciencia',
      top: '8%',
      // minHeight: '2.5rem',
      fontSize: '14px',
    }),
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <label>{required ? `${label}*` : label}</label>
      </div>
      <div data-error={Boolean((meta.error || meta.submitError) && meta.touched)}>
        <TimezoneSelect
          {...input}
          value={input.value}
          onChange={input.onChange}
          components={{ DropdownIndicator, IndicatorSeparator: () => null }}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          placeholder={placeholder}
          styles={customStyles}
          maxMenuHeight={180}
          menuPlacement={'auto'}
        />
      </div>
    </div>
  );
};
