import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  UserCredential,
  User as FirebaseUser,
} from 'firebase/auth';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { auth } from '../../../firebase-config';
import app from '../../../firebase-config';
import { doc, setDoc, getDoc, getFirestore, collection, DocumentData } from 'firebase/firestore';
import { navigate } from 'gatsby';
import { sendPasswordResetEmail } from 'firebase/auth';

interface FirebaseContextType {
  user: FirebaseUser | null;
  logIn: (email: any, password: any) => Promise<UserCredential>;
  signUp: (email: any, password: any) => Promise<UserCredential>;
  logOut: () => Promise<void>;
  resetPass: (email: string) => Promise<void>;
  userInfo: UserInformationsType | null;

  modifyUser: (modifiedUserInfos: any) => Promise<void>;
}

interface UserInformationsType {
  City: string;
  'Country/Region': string;
  'First name': string;
  'Last name': string;
  'Phone number': string;
  'State/Region': string;
  'Street address': string;
  Email: string;
}

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export function FirebaseContextProvider({ children }) {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  // console.log('user ', user);
  const [userInfo, setUserInfo] = useState<UserInformationsType | null>(null);
  //console.log('userInfo ', userInfo);

  //const [userInfo, setInformations] = useState<FirebaseUser | null>(null);

  function logIn(email, password) {
    // console.log('logIn', email, password);
    // console.log('logIn ', email, password);
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    // console.log('signUp ', email, password);
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function resetPass(email) {
    //console.log('resetPass ', email);
    return resetPassword(email);
  }

  function logOut() {
    //  console.log('logOut ');

    setUserInfo(null);
    navigate('/');

    return signOut(auth);
  }

  useEffect(() => {
    // console.log('useEffect user ');
    if (user && user !== null) {
      getUser();
      //  console.log('useEffect user > ', user);
    }
  }, [user]);

  const getUser = async () => {
    // console.log('getUser ');
    if (user && user !== null) {
      // console.log('getUser user && user !== null');
      const db = getFirestore(app);
      const docRef = doc(db, 'users', `${user?.email}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log('getUser user && user !== null docSnap.exists()');
        setUserInfo(docSnap.data() as UserInformationsType);
        //navigate('/account/my-profile');
      } else {
        // console.log('getUser user && user !== null Else');
        setUserInfo(null);
        logOut();
      }
    } else {
      logOut();
    }
  };

  const resetPassword = async (email) => {
    //   console.log(' resetPassword');

    sendPasswordResetEmail(auth, email)
      .then(() => {
        //  console.log(' Password reset email sent!');
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };

  const modifyUser = async (modifiedUserInfos) => {
    const db = getFirestore(app);
    const usersRef = collection(db, 'users');
    if (user && user !== null) {
      setDoc(doc(usersRef, `${user?.email}`), {
        'First name': modifiedUserInfos.firstName,
        'Last name': modifiedUserInfos.lastName,
        Email: modifiedUserInfos.email,
        'Phone number': modifiedUserInfos.phoneNumber,
        'Street address': modifiedUserInfos.address,
        City: modifiedUserInfos.city,
        'State/Region': modifiedUserInfos.region,
        'Country/Region': modifiedUserInfos.country,
      });
    }
  };
  useEffect(() => {
    // console.log('useEffect');
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      //  console.log('Auth', currentuser);
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        user,
        logIn,
        signUp,
        logOut,
        userInfo,
        modifyUser,
        resetPass,
        // isUserValidated,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
}

export const UseFirebaseContext = () => {
  const fireBaseContext = useContext(FirebaseContext);
  return fireBaseContext;
};
