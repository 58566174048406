import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { SanityFormBlock } from '../../../graphql-types';
import { usePageContext } from '../context/PageContext';
import * as styles from '../forms/dataForm.module.scss';
import * as s from '../../styles/main.module.scss';
import { SelectField } from '../forms/fields/SelectField';

import { StringField } from '../forms/fields/StringField';

import { SubmitButton } from '../forms/SubmitButton';
import { TimeZoneField } from '../forms/fields/TimeZoneField';
import { Helmet } from 'react-helmet';
import { getHubFormAPI } from '../api/getHubFormAPI';
import { emailLead } from '../api/emailLead';
import axios from 'axios';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export interface FormInput {
  name: string;
  label: string;
  type: string;
  fieldType: string;
  description: string;
  groupName: string;
  placeholder: string;
  required: boolean;
  enabled: boolean;
  labelHidden: boolean;
  options: [{ label: string; value: string }];
}

interface Props {
  item: SanityFormBlock;
  isInPopUp: boolean;
  formId: string;
  sanityMeetingLinks?:
    | ({
        __typename?: 'SanityMeetingHubspot' | undefined;
        _rawLanguage?: any;
        langCode?: string | null | undefined;
        link?: string | null | undefined;
      } | null)[]
    | null
    | undefined;
}

export const required = (values: string) => (values ? undefined : 'Required');

export const pleaseForm = { en: '* Required', es: '* Requerido' };

export const HubspotForm = ({ item, isInPopUp, formId, sanityMeetingLinks }: Props) => {
  console.log('sanityMeetingLinks ', sanityMeetingLinks);
  const isMeeting = item?.formSelector ? item?.formSelector === 'meeting' : false;

  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstname, setFirstname] = useState<string>('');
  const [language, setLanguage] = useState<string>('');

  const [formFields, setFormFields] = React.useState([]);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { lang, afmc, campaign } = usePageContext();

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [serverState, setServerState] = useState({
    submitting: false,
    status: {} || null,
  });
  const handleServerResponse = (ok, msg, form) => {
    // console.log('handleServerResponse ', ok, msg, form);
    setServerState({
      submitting: false,
      status: { ok, msg } || null,
    });
    if (ok) {
      setSubmitted(true);
      // form.reset();
    }
  };

  useEffect(() => {
    getHubspotForm(formId);
  }, []);

  const getHubspotForm = async (hsFormId) => {
    // console.log('getHubspotForm ');
    getHubFormAPI(hsFormId)
      .then(async (res) => {
        // console.log('getHubFormAPI res  :', res);
        setFormFields(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (e) => {
    console.log('e', e);
    if (e.hs_language) {
      setLanguage(e.hs_language);
    }
    if (e.email) {
      setEmail(e.email);
    }
    const formHere = e.target;
    const data = new FormData(formHere);
    const portalId = '21307994';
    const formGuid = formId;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const fields = Object.keys(e).map((key) => ({
      name: key,
      value: e[key],
    }));
    console.log('fields ', fields);

    const resp = emailLead({
      email: e.email,
      campaign: campaign,
      code: afmc,
      afmc: afmc,
    }).then(async (res) => {});

    // console.log('fields ', fields);
    try {
      const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          portalId,
          formGuid,
          fields,
        },
        config,
      );
      // console.log('response ', response);
      if (response.status === 200) {
        setSubmitted(true);
      }
      return response;
    } catch (error) {}

    //  setServerState({ submitting: true, status: {} });
    // try {
    //   if (executeRecaptcha) {
    //     const recaptchaToken = await executeRecaptcha('login');
    //     data.append('recaptchaToken', recaptchaToken);
    //     axios({
    //       method: 'POST',
    //       url: 'https://getform.io/f/a231c4ac-4a6a-44f2-afb7-0e3f08360fe8',
    //       data: data,
    //     })
    //       .then((r) => {
    //         handleServerResponse(true, 'Thanks!', formHere);
    //       })
    //       .catch((r) => {
    //         handleServerResponse(false, r.response.error, formHere);
    //       });
    //   }
    // } catch (error) {}
  };
  return (
    <>
      <div className={`${styles.wrapper} ${isInPopUp ? s.paddings20Px : null}`}>
        {submitted ? (
          <>
            {!isMeeting ? (
              <div
                className={`${s.width100} ${s.height100} ${s.maxWidth565Px} ${s.minHeight280Px}  ${s.justifyContentCenter}  ${s.alignContentCenter}`}
              >
                {item?._rawSuccessMessage ? item?._rawSuccessMessage?.[lang] : 'Request send'}
              </div>
            ) : (
              <div id="none">
                <Helmet
                  script={[
                    <script
                      type="text/javascript"
                      src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                    ></script>,
                  ]}
                />
                <iframe
                  title={'hubspot'}
                  style={{
                    width: '100%',
                    height: '680px',
                  }}
                  src={`${
                    sanityMeetingLinks?.find((e) => e?.langCode === language)?.link
                  }/?embed=true&firstName=${firstname}&lastName=${lastname}&email=${email}`}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <Form
              onSubmit={handleSubmit}
              render={({ handleSubmit, values, errors, form, submitting, pristine }) => (
                <form
                  onSubmit={handleSubmit}

                  // action="https://getform.io/f/a231c4ac-4a6a-44f2-afb7-0e3f08360fe8"
                  // method="POST"
                >
                  {formFields?.map((e: FormInput) => {
                    if (e?.fieldType === 'timezone')
                      return (
                        <Field
                          key={e?.name as string}
                          name={e?.name as string}
                          component={TimeZoneField}
                          label={e?.label}
                          validate={e?.required ? required : undefined}
                          required={e?.required}
                          placeholder={e?.label}
                        />
                      );
                    if (e?.fieldType === 'select')
                      return (
                        <Field
                          key={e?.name as string}
                          name={e?.name as string}
                          placeholder={e?.label}
                          component={SelectField}
                          options={e?.options}
                          label={e?.label}
                          validate={e?.required ? required : undefined}
                          required={e?.required}
                          fieldType={e?.fieldType}
                        />
                      );
                    return (
                      <Field
                        key={e?.name as string}
                        name={e?.name as string}
                        fieldType={e?.fieldType}
                        component={StringField}
                        label={e?.label}
                        validate={e?.required ? required : undefined}
                        required={e?.required}
                        placeholder={e?.label}
                      />
                    );
                  })}

                  <div>
                    <div
                      className={`${styles.requiredMessage} ${
                        JSON.stringify(errors) === '{}' ? null : styles.red
                      }`}
                    >
                      {pleaseForm?.[lang]}
                    </div>
                    <SubmitButton onSubmit={() => {}} />
                    {/* {JSON.stringify(errors) === '{}' ? (
                      <SubmitButton onSubmit={() => {}} />
                    ) : (
                      <SubmitButton />
                    )} */}
                  </div>
                </form>
              )}
            />
          </>
        )}
      </div>
    </>
  );
};
