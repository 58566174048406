import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import * as s from '../../styles/main.module.scss';

import { GatsbyImage } from 'gatsby-plugin-image';
import { usePageContext } from '../context/PageContext';
import { localizePath } from '../items/LotCard';
import * as styles from './headerDesktop.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
interface Props {
  data: any;
  scrollState: boolean;
}

export const HeaderDesktop: React.FC<Props> = ({ data, scrollState }) => {
  const logoSrcWhite = data?.allSanityHeaderLogo?.nodes[0]?.logoWhite?.asset?.gatsbyImageData;
  const logoSrcDark = data?.allSanityHeaderLogo?.nodes[0]?.logoDark?.asset?.gatsbyImageData;
  const { lang, setLang, originalPath, article } = usePageContext();
  const pagesList = data?.allSanityDynamicPage?.nodes;
  const ecovillasList = data?.allSanityEcoVilla?.nodes;

  const { setFormPopUpState, setLoginDrawerState, loginDrawerState, setMenuState } =
    usePageContext();
  const ctaText = data?.allSanitySetUpACallButton?.nodes[0]?._rawCtaText?.[lang];

  useEffect(() => {}, [scrollState]);

  const openPopup = () => {
    setFormPopUpState({ popupState: 'meeting' });
    setLoginDrawerState({ loginDrawer: false });
    setMenuState({ menuStateOpen: false });
  };

  const openLoginDrawer = () => {
    // console.log('openLoginDrawer ');
    if (loginDrawerState.loginDrawer === false) {
      setLoginDrawerState({ loginDrawer: true });
      setFormPopUpState({ popupState: 'closed' });
      setMenuState({ menuStateOpen: false });
    } else {
      setLoginDrawerState({ loginDrawer: false });
    }
  };

  ////////INSERT  ECOVILLAS LINK LIST///////////////////////////////////////////////////

  const insertLogo = () => {
    return (
      <Link to={localizePath('/', lang)}>
        <div
          id="HeaderLogoBox"
          className={`
          ${s.height45Px}
          ${s.width131Px}
          ${s.paddingLeft50Px}
          ${s.topMinus16Px}
        
          `}
        >
          <GatsbyImage
            className={`
          
            ${s.height45Px}
            ${s.width131Px}
            `}
            image={article || scrollState === true ? logoSrcDark : logoSrcWhite}
            objectFit="contain"
            alt="background"
          />
        </div>
      </Link>
    );
  };

  ////////INSERT  ECOVILLA ITEM///////////////////////////////////////////////////

  const insertEcovillasLink = ecovillasList?.map((ecovilla, index) => {
    const ecovillaName = ecovilla.name;
    const ecovillaSlug = 'ecovillas' + '/' + ecovilla.slug?.current;

    return (
      <Link
        key={ecovilla.id}
        to={localizePath('/' + ecovillaSlug ?? '', lang)}
        activeClassName={styles.bold}
        className={`${styles.link} ${styles.fake}`}
      >
        <div
          key={ecovilla.id}
          id="EcovillaItem"
          className={`
                ${s.textAlignLeft}
                ${s.whiteSpaceNowrap}
                ${s.textTransformUppercase}
                ${s.fontSize12Px}
                ${s.colorDarkgrey}
                ${s.lineHeight1And5}
                ${s.letterSpacing1And5Px}
                ${s.marginBottom10Px}
                `}
        >
          <p>{ecovillaName}</p>
          <p>{ecovillaName}</p>
        </div>
      </Link>
    );
  });

  ////////INSERT  All Second level pages ITEM///////////////////////////////////////////////////

  const insertSecondLevelLinks = (parentSlug, itemList) => {
    const items = itemList?.map((item, index) => {
      const itemName = item?._rawPageTitleMenu?.[lang];
      const completeSlug = parentSlug + '/' + item.slug?.current;

      return (
        <Link
          key={item.id}
          to={localizePath('/' + completeSlug ?? '', lang)}
          activeClassName={styles.bold}
          className={`${styles.link} ${styles.fake} `}
        >
          <div
            key={item.id}
            className={`
                ${s.textAlignLeft}
                ${s.whiteSpaceNowrap}
                ${s.textTransformUppercase}
                ${s.fontSize12Px}
                ${s.colorDarkgrey}
                ${s.lineHeight1And5}
                ${s.letterSpacing1And5Px}
                ${s.marginBottom10Px}
                `}
          >
            <p>{itemName}</p>
            <p>{itemName}</p>
          </div>
        </Link>
      );
    });
    return (
      <div
        className={`
          ${styles.subMenu}
          ${s.widthAuto}
          ${s.flexDirectionColumn}
          ${s.justifyContentFlexEnd}
          ${s.backGroundColorWhite}
          ${s.positionAbsolute}
          ${s.wrapper20Px}
          ${s.paddingBottom10Px}
          ${s.paddingTop20Px}
        `}
      >
        {items}
        {parentSlug === 'ecovillas' ? insertEcovillasLink : null}
      </div>
    );
  };

  //////// MENU MAP WITH ACTIVE ITEMS///////////////////////////////////////////////////

  const activePagesList = pagesList.filter(function (el) {
    return el.activeInMainMenu === true;
  });

  const insertMenuLink = activePagesList?.map((item, index) => {
    const pageTitle = item?._rawPageTitleMenu?.[lang];
    let colorGrey = true;
    let idVal = 'MenuItem';

    const slug = item?.slug?.current as string;
    let linkToVal = '/' + slug ?? '';
    if (item?.childPagesReferenceList.length > 0) {
      linkToVal = '';
    }

    return (
      <div
        key={item.id}
        className={`
      
        ${styles.menuLink} 
        ${s.cursorPointer}

        `}
      >
        <div
          id={idVal}
          // ${s.wrapper20Px}
          className={`
                ${s.alignItemsCenter}
                ${s.height40Px}
               
                ${article || scrollState === true ? s.colorDarkgrey : s.colorWhite}
                ${s.cursorPointer}
                ${styles.topLink}
      
                `}
        >
          {/* {item?.childPagesReferenceList.length > 0 ? insertLink(item?.id,linkToVal,pageTitle) : insertDiv(item?.id,linkToVal,pageTitle)} */}
          <Link
            key={item.id}
            to={localizePath(linkToVal, lang)}
            className={`${styles.link} ${styles.fake}`}
          >
            <div
              className={` ${s.pointerEventsNone}
                ${s.textAlignLeft}
                ${s.whiteSpaceNowrap}
                ${s.textTransformUppercase}
                ${s.fontSize12Px}
                ${s.lineHeight1And5}
                ${s.letterSpacing1And5Px}
                ${originalPath.includes(slug) && styles.bold}
                `}
            >
              <p>{pageTitle}</p>
              <p>{pageTitle}</p>
            </div>
          </Link>
        </div>
        {item?.childPagesReferenceList.length > 0
          ? insertSecondLevelLinks(slug, item?.childPagesReferenceList)
          : null}
      </div>
    );
  });

  const insertSwitchLanguage = () => {
    //console.log("////////////////////////////////////insertSwitchLanguage")
    return (
      <div
        // style={{ left: windowSize.width - 80 }}
        id="SwitchLanguageBox"
        className={`
        ${s.flexDirectionRow}
        ${s.height40Px}
        ${s.paddingLeft20Px}
        `}
      >
        <div
          id="SwitchLanguageEnglish"
          onClick={() => {
            if (lang === 'es') {
              setLang('en');
              navigate(originalPath);
            }
          }}
          style={{ lineHeight: 1.5 }}
          className={`

            ${s.widthAuto}
            ${s.textTransformUppercase}
            ${s.fontFamilyPoppinsReg}
            ${s.fontSize12Px}
            ${s.letterSpacing1And5Px}
            ${s.paddingLeft20Px}
            ${s.height40Px}
            ${s.cursorPointer}
            ${s.alignItemsCenter}

            ${
              lang === 'en' && (article || scrollState === true)
                ? s.colorDarkgrey
                : lang === 'en' && scrollState === false
                ? s.colorWhite
                : s.colorClearGrey
            }

          `}
        >
          <p className={`${lang === 'en' ? styles.selected : ''} ${styles.language} `}>EN</p>
        </div>

        <div
          id="SwitchLanguageSpanish"
          style={{ lineHeight: 1.5 }}
          onClick={() => {
            if (lang === 'en') {
              setLang('es');
              navigate('/es' + originalPath);
            }
          }}
          className={`
            ${s.textTransformUppercase}
            ${s.widthAuto}
            ${s.height40Px}
            ${s.cursorPointer}
            ${s.fontFamilyPoppinsReg}
            ${s.fontSize12Px}
            ${s.letterSpacing1And5Px}
            ${s.paddingLeft20Px}
            ${s.alignItemsCenter}
            ${s.hoverEffect}
            ${
              lang === 'es' && (article || scrollState === true)
                ? s.colorDarkgrey
                : lang === 'es' && scrollState === false
                ? s.colorWhite
                : s.colorClearGrey
            }

            ${lang === 'es' && s.fontFamilyPoppinsBld}


          `}
        >
          <p className={`${lang === 'es' ? styles.selected : ''} ${styles.language}`}>ES</p>
        </div>
      </div>
    );
  };

  ////////COMPONENT RENDER////////////////////////////////////////////////////

  return (
    <div
      id="HeaderDesktopBox"
      className={`
    ${s.height120Px}
    ${s.widthAuto}
  
  `}
    >
      {article || (scrollState === false && <div className={s.navbarBg} />)}
      <div
        id="SetUpACallStripe"
        onClick={() => openPopup()}
        className={`
      ${s.height30Px}
      ${s.backGroundColorDarkgreen}
      ${s.flexDirectionRow}
      ${s.alignItemsCenter}
      ${s.justifyContentFlexEnd}
      ${s.marginLeftAuto}
      ${s.paddingRight20Px}
      ${s.cursorPointer}
    `}
      >
        <img
          className={`${s.width15Px} ${s.height15Px}
          ${s.marginRight10Px}
            `}
          src="/svgs/icon_phone.svg"
          alt="icon-phone"
        />

        <p
          className={`
          ${s.widthFitContent}
          ${s.textAlignRight}
          ${s.textTransformUppercase}
          ${s.fontSize12Px}
          ${s.lineHeight1And5}
          ${s.letterSpacing1And5Px}
          ${s.alignItemsCenter}
          ${s.height20Px}
          ${s.fontFamilyPoppinsReg}
          ${s.colorWhite}
          `}
        >
          {ctaText}
        </p>
      </div>

      <div
        id="HeaderDesktop"
        className={`
      ${s.height75Px}
  
      ${s.width100}
      ${article || scrollState === true ? s.backGroundColorWhite : s.backGroundColorClear}
      
    `}
      >
        <div
          id="MainMenuLinksBox"
          className={`
          ${s.width100}
          ${s.height75Px}
          ${s.flexDirectionRow}
          ${s.justifyContentCenter}
          ${s.alignItemsFlexEnd}
    
      
    `}
        >
          {insertLogo()}
          <div
            id="MainMenuLinksBoxIn"
            className={`
          ${s.widthAuto}
          ${s.maxWidth1920Px}
          ${s.height40Px}
          ${s.flexDirectionRow}
          ${s.justifyContentFlexEnd}
          ${s.alignItemsFlexEnd}
          ${s.paddingRight50Px}

    `}
          >
            {insertMenuLink}

            <div
              id="account"
              onClick={() => {
                openLoginDrawer();
              }}
              style={{ minWidth: 40 }}
              className={`
      
                    ${s.flexDirectionRow}
                    ${s.width40Px}
                    ${s.height40Px}
                    ${s.backGroundColorClear}
                    ${s.cursorPointer}
                    ${s.alignItemsCenter}
                    ${s.justifyContentCenter}
                    ${s.colorDarkgreen}
                    ${s.objectFitContain}
                    ${s.fontSize16Px}
                  `}
            >
              <FontAwesomeIcon
                icon={faUser as any}
                color={article || scrollState === true ? '#384735' : '#ffffff'}
              />
            </div>
            {insertSwitchLanguage()}
          </div>
        </div>
      </div>
    </div>
  );
};
