import React, { ReactNode, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';
import * as s from '../../styles/main.module.scss';

import { GatsbyImage } from 'gatsby-plugin-image';
import { gsap } from 'gsap';
import { usePageContext } from '../context/PageContext';
import { useWindowSize } from '../../hooks/useWindowSize';
import { localizePath } from '../items/LotCard';

interface Props {
  data: any;
  scrollState: boolean;
}

export const HeaderMobile: React.FC<Props> = ({ data, scrollState }) => {
  const logoSrcWhite = data?.allSanityHeaderLogo?.nodes[0]?.logoWhite?.asset?.gatsbyImageData;
  const logoSrcDark = data?.allSanityHeaderLogo?.nodes[0]?.logoDark?.asset?.gatsbyImageData;
  const StripeTop = useRef<HTMLDivElement>(null);
  const StripeMiddle1 = useRef<HTMLDivElement>(null);
  const StripeMiddle2 = useRef<HTMLDivElement>(null);
  const StripeBottom = useRef<HTMLDivElement>(null);
  const { lang, article } = usePageContext();
  const { menuState, setMenuState } = usePageContext();
  const { formPopUpState, setFormPopUpState, setLoginDrawerState } = usePageContext();
  const ctaText = data?.allSanitySetUpACallButton?.nodes[0]?._rawCtaText?.[lang];

  const handleBurgerClick = () => {
    if (menuState.menuStateOpen === false) {
      setMenuState({ menuStateOpen: true });
      setFormPopUpState({ popupState: 'closed' });
      setLoginDrawerState({ loginDrawer: false });
    } else {
      setMenuState({ menuStateOpen: false });
    }
  };

  const openPopup = () => {
    setFormPopUpState({ popupState: 'meeting' });
    setMenuState({ menuStateOpen: false });
    setLoginDrawerState({ loginDrawer: false });
  };

  useEffect(() => {
    if (menuState.menuStateOpen === true) {
      const StripeTopCurrent = StripeTop.current;
      const StripeBottomCurrent = StripeBottom.current;
      const StripeMiddle1Current = StripeMiddle1.current;
      const StripeMiddle2Current = StripeMiddle2.current;
      const animOpenMenu1 = gsap.to(StripeTopCurrent, {
        opacity: 0,
        y: -10,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });
      const animOpenMenu2 = gsap.to(StripeBottomCurrent, {
        opacity: 0,
        y: 10,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });

      const animOpenMenu3 = gsap.to(StripeMiddle1Current, {
        rotation: 30,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });
      const animOpenMenu4 = gsap.to(StripeMiddle2Current, {
        rotation: -30,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });

      return () => {
        animOpenMenu1.kill();
        animOpenMenu2.kill();
        animOpenMenu3.kill();
        animOpenMenu4.kill();
      };
    } else {
      const StripeTopCurrent = StripeTop.current;
      const StripeBottomCurrent = StripeBottom.current;

      const StripeMiddle1Current = StripeMiddle1.current;
      const StripeMiddle2Current = StripeMiddle2.current;

      const animOpenMenu1 = gsap.to(StripeTopCurrent, {
        opacity: 1,
        y: 0,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });
      const animOpenMenu2 = gsap.to(StripeBottomCurrent, {
        opacity: 1,
        y: 0,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });

      const animOpenMenu3 = gsap.to(StripeMiddle1Current, {
        rotation: 0,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });
      const animOpenMenu4 = gsap.to(StripeMiddle2Current, {
        rotation: 0,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });

      return () => {
        animOpenMenu1.kill();
        animOpenMenu2.kill();
        animOpenMenu3.kill();
        animOpenMenu4.kill();
      };
    }
  }, [menuState.menuStateOpen]);

  ////////COMPONENT RENDER////////////////////////////////////////////////////

  return (
    <div
      id="HeaderMobileBox"
      className={`
      ${s.height94Px}
      ${s.widthAuto}
      `}
    >
      <div
        id="SetUpACallStripe"
        onClick={() => openPopup()}
        className={`
      ${s.height30Px}
      ${s.backGroundColorDarkgreen}
      ${s.flexDirectionRow}
      ${s.alignItemsCenter}
      ${s.justifyContentCenter}
  

      ${s.cursorPointer}
    `}
      >
        <img
          className={`${s.width15Px} ${s.height15Px}
          ${s.marginRight10Px}
            `}
          src="/svgs/icon_phone.svg"
          alt="icon-phone"
        />

        <p
          className={`
          ${s.widthFitContent}
          ${s.textAlignCenter}
          ${s.textTransformUppercase}
          ${s.fontSize12Px}
          ${s.lineHeight1And5}
          ${s.letterSpacing1And5Px}
          ${s.alignItemsCenter}
          ${s.height20Px}
          ${s.fontFamilyPoppinsReg}
          ${s.colorWhite}
          `}
        >
          {ctaText}
        </p>
      </div>

      <div
        id="HeaderMobile"
        className={`
        ${
          !article && scrollState === false && menuState.menuStateOpen === false
            ? s.backGroundColorClear
            : s.backGroundColorWhite
        }
        ${s.height64Px}
        ${s.justifyContentFlexEnd}
        ${s.flexDirectionRow}
        ${s.alignItemsCenter}
        ${s.wrapper20Px}
  `}
      >
        <Link to={localizePath('/', lang)}>
          <div
            id="HeaderLogoBox"
            className={`
          ${s.height40Px}
            ${s.width110Px}
            ${s.topMinus5Px}
          `}
          >
            <GatsbyImage
              className={`
          
            ${s.height40Px}
            ${s.width110Px}
           
            `}
              image={
                !article && scrollState === false && menuState.menuStateOpen === false
                  ? logoSrcWhite
                  : logoSrcDark
              }
              objectFit="contain"
              alt="background"
            />
          </div>
        </Link>

        <div
          id="BurgerMenu"
          onClick={() => handleBurgerClick()}
          className={`
        ${s.height32Px}
        ${s.width32Px}
        
        ${s.justifyContentCenter}
        ${s.alignItemsCenter}
      `}
        >
          <div
            id="StripesBox"
            className={`
        ${s.height17Px}
        ${s.width40Px}
        ${s.flexDirectionColumn}
        ${s.justifyContentSpaceBetween}
      `}
          >
            <div
              ref={StripeTop}
              id="StripeTop"
              className={`
            ${s.height1Px}
            ${s.width40Px}
            ${
              !article && scrollState === false && menuState.menuStateOpen === false
                ? s.backGroundColorWhite
                : s.backGroundColorDarkgreen
            }
      `}
            />

            <div
              id="StripeMiddleBox"
              className={`
            ${s.height1Px}
            ${s.width40Px} `}
            >
              <div
                ref={StripeMiddle1}
                id="StripeMiddle1"
                className={`
              ${s.height1Px}
              ${s.width40Px}
              ${s.positionAbsolute}
              ${
                !article && scrollState === false && menuState.menuStateOpen === false
                  ? s.backGroundColorWhite
                  : s.backGroundColorDarkgreen
              }
             `}
              />
              <div
                ref={StripeMiddle2}
                id="StripeMiddle2"
                className={`
        ${s.height1Px}
        ${s.width40Px}
     
        ${
          !article && scrollState === false && menuState.menuStateOpen === false
            ? s.backGroundColorWhite
            : s.backGroundColorDarkgreen
        }
      `}
              />
            </div>
            <div
              ref={StripeBottom}
              id="StripeBottom"
              className={`
        ${s.height1Px}
        ${s.width40Px}

        ${
          !article && scrollState === false && menuState.menuStateOpen === false
            ? s.backGroundColorWhite
            : s.backGroundColorDarkgreen
        }
      `}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
