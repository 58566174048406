import React, { useEffect, useState } from 'react';

import * as s from '../../styles/main.module.scss';
import { usePageContext } from '../context/PageContext';
import { UseFirebaseContext } from '../context/FirebaseContext';
import { navigate } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { LoginDrawerQuery } from 'graphql-types';

import { ButtonMore } from '../items/ButtonMore';
interface LoginDrawerProps {
  //isUserLogged: boolean;
}

export const LoginDrawer: React.FC<LoginDrawerProps> = ({}) => {
  const datas = useStaticQuery<LoginDrawerQuery>(loginDrawerQuery);
  const data = datas?.allSanityAccountSettings?.nodes?.[0];

  const { lang, setLoginDrawerState, loginDrawerState } = usePageContext();

  const [isUserLogged, setIsUserLogged] = useState(false);
  const fireBaseContext = UseFirebaseContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  /////////////
  useEffect(() => {
    if (
      fireBaseContext?.user &&
      fireBaseContext?.user !== null &&
      fireBaseContext?.userInfo !== null
    ) {
      setIsUserLogged(true);
    } else {
      setIsUserLogged(false);
    }
  }, [fireBaseContext?.user, fireBaseContext?.userInfo]);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    // console.log('handleLogin');
    e.preventDefault();

    fireBaseContext
      ?.logIn(email, password)
      .then((userCredential) => {
        if (
          fireBaseContext?.user &&
          fireBaseContext?.user !== null &&
          fireBaseContext?.userInfo !== null
        ) {
          navigate('/account/my-profile');
        } else {
          //setErrorMessage('No validated account');
        }
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          setErrorMessage('No corresponding account, try to register');
        }
        if (error.code === 'auth/wrong-password') {
          setErrorMessage('Wrong password');
        }
        if (error.code === 'auth/invalid-email') {
          setErrorMessage('Invalid email');
        }
      });
  };

  const handleSignOut = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    fireBaseContext
      ?.logOut()
      .then(() => {})
      .catch((error) => {
        // An error happened.
        //   console.log('>>>>>>>>>>> error FirebaseError', error.FirebaseError);
      });
  };

  return (
    <div
      id="LoginDrawer"
      className={`
      ${s.width300Px}
      ${s.height100}
      ${s.positionFixed}
      ${s.right0}
      ${s.zIndex10000}
      ${s.justifyContentCenter}
      ${s.alignItemsCenter}
      ${s.pointerEventsNone}
      ${s.backGroundTransparent}
      `}
    >
      <div
        id="LoginDrawerBox"
        className={`
        ${s.loginFormBoxShadow}
        ${s.width300Px}
        ${s.heightAuto}
        ${s.backGroundColorWhite}
        ${s.wrapper30Px}
        ${s.paddingTop30Px}
        ${s.paddingBottom50Px}
        ${s.flexDirectionColumn}
        ${s.gap30Px}
      `}
      >
        <div
          id="closeButtonBox"
          onClick={() => setLoginDrawerState({ loginDrawer: false })}
          className={`${s.width100} ${s.height40Px} ${s.cursorPointer} ${s.pointerEventsAuto}  ${s.justifyContentFlexEnd}  `}
        >
          <img
            className={`${s.width40Px} ${s.height40Px} ${s.padding10Px}`}
            src="/svgs/close_cross_grey.svg"
            alt="SVG as an image"
          />
        </div>
        <div
          id="LoginDrawerLogin"
          className={`
        ${s.width100}
        ${s.heightAuto}
        ${s.gap20Px}

      `}
        >
          <p
            className={`
          ${s.fontFamilyPaciencia}
          ${s.fontSize30Px}
          ${s.textAlignLeft}
          ${s.widthAuto}
          ${s.colorDarkgreen}
       `}
          >
            {data?._rawMyAccountLabel?.[lang]}
          </p>

          <div
            id="login"
            className={`
              ${s.formBox}
              ${s.displayFlex}
              ${s.flexDirectionColumn}
              ${s.gap10Px}
           `}
          >
            {isUserLogged === true && (
              <div
                className={`${s.pointerEventsAuto}  ${s.width100} ${s.justifyContentFlexEnd} ${s.alignItemsFlexEnd} `}
              >
                {' '}
                <ButtonMore color="dark" button={data?.goToAccountButton} />
              </div>
            )}

            {isUserLogged === false && (
              <form onSubmit={handleLogin}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />

                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />

                {errorMessage && (
                  <div
                    className={`
                    ${s.colorTerra}
                    ${s.fontFamilyPoppinsReg}
                    ${s.fontSize12Px}
                  `}
                  >
                    {errorMessage}
                  </div>
                )}
                <button type="submit">{data?._rawSignInButton?.[lang]}</button>
              </form>
            )}
          </div>

          {isUserLogged === false && (
            <div
              className={`${s.pointerEventsAuto}  ${s.width100} ${s.justifyContentFlexEnd} ${s.alignItemsFlexEnd} `}
            >
              <div
                onClick={() => navigate('/password-reset')}
                className={`
        ${s.width100}
        ${s.fontFamilyPoppinsReg}
        ${s.fontSize12Px}
        ${s.textAlignRight}
        ${s.widthAuto}
        ${s.colorClearGrey}
        ${s.lineHeight1And5}
        ${s.cursorPointer} 
        ${s.textDecorationUnderline} 

      `}
              >
                {data?._rawForgotPasswordLink?.[lang]}
              </div>
            </div>
          )}
        </div>

        {isUserLogged === false && data.activeInLoginDrawer && (
          <>
            {' '}
            <div
              className={`
        ${s.width100}
        ${s.height1Px}
        ${s.backGroundColorDarkgreen}
      `}
            />
            <div
              id="LoginDrawerRegister"
              className={`
        ${s.width100}
        ${s.heightAuto}
        ${s.displayFlex}
        ${s.flexDirectionColumn}
        ${s.gap10Px}
      `}
            >
              <p
                className={`
          ${s.fontFamilyPaciencia}
          ${s.fontSize30Px}
          ${s.textAlignLeft}
          ${s.widthAuto}
          ${s.colorDarkgreen}
       `}
              >
                {data?._rawRegisterLabel?.[lang]}
              </p>

              <p
                className={`
          ${s.fontFamilyPoppinsReg}
          ${s.fontSize12Px}
          ${s.textAlignLeft}
          ${s.widthAuto}
          ${s.colorDarkgrey}
          ${s.lineHeight1And5}
       `}
              >
                {data?._rawRegisterText?.[lang]}
              </p>
              <div
                className={`${s.pointerEventsAuto}  ${s.width100} ${s.justifyContentFlexEnd} ${s.alignItemsFlexEnd} `}
              >
                {' '}
                <ButtonMore color="dark" button={data?.registerButton} />
              </div>
            </div>
          </>
        )}

        {isUserLogged === true && (
          <>
            <div
              className={`
${s.width100}
${s.height1Px}
${s.backGroundColorDarkgreen}
`}
            />
            <div
              id="LoginDrawerRegister"
              className={`
        ${s.width100}
        ${s.heightAuto}
        ${s.displayFlex}
        ${s.flexDirectionColumn}
        ${s.gap10Px}
        ${s.formBox}
      `}
            >
              <p
                className={`
          ${s.fontFamilyPaciencia}
          ${s.fontSize30Px}
          ${s.textAlignLeft}
          ${s.widthAuto}
          ${s.colorDarkgreen}
       `}
              >
                {data?._rawSignOutLabel?.[lang]}
              </p>

              <p
                className={`
          ${s.fontFamilyPoppinsReg}
          ${s.fontSize12Px}
          ${s.textAlignLeft}
          ${s.widthAuto}
          ${s.colorDarkgrey}
          ${s.lineHeight1And5}
       `}
              >
                {data?._rawSignOutText?.[lang]}
              </p>

              <form onSubmit={handleSignOut}>
                <button className={s.cursorPointer} id="signout">
                  {data?._rawSignOutButton?.[lang]}
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const loginDrawerQuery = graphql`
  query loginDrawer {
    allSanityAccountSettings {
      nodes {
        _rawForgotPasswordLabel
        _rawGoToAccountButton
        _rawMyAccountLabel
        _rawPasswordLabel
        _rawEmailLabel
        _rawRememberMeLabel
        _rawRegisterText
        _rawRegisterLabel
        _rawRegisterButton
        _rawSignOutButton
        _rawSignInButton
        _rawSignOutLabel
        _rawSignOutText
        _rawForgotPasswordLink
        activeInLoginDrawer

        registerButton {
          _rawCta
          formLink
          formBlockRef {
            formSelector
          }
        }
        goToAccountButton {
          siteLink {
            ... on SanityAccountPage {
              _type
              slug {
                current
              }
            }
          }
          formLink
          formBlockRef {
            formSelector
          }
          externalLink
          _rawCta
        }
      }
    }
  }
`;
