import { PageProps } from 'gatsby';
import React, { useState, useContext, createContext, useEffect } from 'react';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { fullScreen } from '../items/video.module.scss';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { FirebaseContext } from '../../../gatsby-browser';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
interface IPathState {
  path: string;
}

interface IMenuState {
  menuStateOpen: boolean;
}

interface IHeaderState {
  headerStateVisible: boolean;
}

interface IFormPopState {
  popupState: string;
}

interface ILoginDrawer {
  loginDrawer: boolean;
}

interface ILangState {
  lang: string;
}

interface IWindowState {
  width: number;
  height: number;
}

interface IUserState {
  logged: boolean;
  email: string;
  firstName: string;
  lastName: string;
}

interface IPageContext {
  headerState: IHeaderState;
  setHeaderState: (value: IHeaderState) => void;

  menuState: IMenuState;
  setMenuState: (value: IMenuState) => void;

  loginDrawerState: ILoginDrawer;
  setLoginDrawerState: (value: ILoginDrawer) => void;

  formPopUpState: IFormPopState;
  setFormPopUpState: (value: IFormPopState) => void;

  pathState: IPathState;
  setPathState: (value: IPathState) => void;

  lang: string;
  setLang: (lang: string) => void;

  windowState: IWindowState;
  setWindowState: (value: IWindowState) => void;

  originalPath: string;
  defaultLanguage: string;

  campaign: string;
  setCampaign: (s: string) => void;
  afmc: string;
  setAfmc: (s: string) => void;
  article?: boolean;
  fullScreen: boolean;
  setFullScreen: (bool) => void;
}

const PageContext = createContext<IPageContext | undefined>(undefined);

export const PageContextProvider: React.FC<{ pageContext: any; children: React.ReactNode }> = ({
  pageContext,
  children,
}) => {
  const [loginDrawerState, setLoginDrawerState] = useState<ILoginDrawer>({
    loginDrawer: false,
  });

  const [menuState, setMenuState] = useState<IMenuState>({
    menuStateOpen: false,
  });

  const [headerState, setHeaderState] = useState<IHeaderState>({
    headerStateVisible: true,
  });

  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [campaign, setCampaign] = useLocalStorage('campaign', '');
  const [afmc, setAfmc] = useLocalStorage('afmc', '');

  // console.log('campaign', campaign);
  // console.log('afmc', afmc);

  const [formPopUpState, setFormPopUpState] = useState<IFormPopState>({
    popupState: 'closed',
  });

  const [lang, setLang] = useState<string>(pageContext.lang);

  const [windowState, setWindowState] = useState<IWindowState>({
    width: 0,
    height: 0,
  });

  // useEffect(() => {
  //   if (loginDrawerState.loginDrawer === true) {
  //     setFormPopUpState({ popupState: 'closed' });
  //     setMenuState({ menuStateOpen: false });
  //   }
  //   if (menuState.menuStateOpen === true) {
  //     setFormPopUpState({ popupState: 'closed' });
  //     setLoginDrawerState({ loginDrawer: false });
  //   }
  // }, [formPopUpState.popupState, menuState.menuStateOpen, loginDrawerState.loginDrawer]);

  return (
    <PageContext.Provider
      value={{
        ...pageContext,

        menuState: menuState,
        setMenuState: setMenuState,

        loginDrawerState: loginDrawerState,
        setLoginDrawerState: setLoginDrawerState,

        headerState: headerState,
        setHeaderState: setHeaderState,

        lang,
        setLang,

        formPopUpState: formPopUpState,
        setFormPopUpState: setFormPopUpState,

        windowState: windowState,
        setWindowState: setWindowState,
        afmc,
        setAfmc,
        campaign,
        setCampaign,
        fullScreen,
        setFullScreen,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => {
  const pageContext = useContext(PageContext);
  if (!pageContext) throw new Error('No PageContext.Provider found when calling usePageContext.');
  return pageContext;
};
