import React, { useEffect, useRef } from 'react';

import * as s from '../../styles/main.module.scss';

import { graphql, useStaticQuery } from 'gatsby';

import { FormPopQuery, SanityFormBlock } from 'graphql-types';
// import { GatsbyImage } from 'gatsby-plugin-image';
import { gsap } from 'gsap';
import { usePageContext } from '../context/PageContext';
import { useWindowSize } from '../../hooks/useWindowSize';
// import { NewsletterFormPop } from './NewsletterFormPop';
import { FormPopBox } from './FormPopBox';
import { MeetingFormPop } from './MeetingFormPop';
import { FormBlock } from '../blocks/FormBlock';

interface LayoutProps {
  // children: ReactNode;
}

export const FormPop: React.FC<LayoutProps> = ({}) => {
  // console.log('FormPop');
  const StripeTop = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const data = useStaticQuery<FormPopQuery>(formPopQuery);

  const { lang } = usePageContext();
  const allFormsTitle = data?.allSanityTitlePopForm?.nodes;
  const sanityMeetingLinks = data?.sanityMeeting?.mettingLinks;
  // console.log('FormPop sanityMeetingLinks ', sanityMeetingLinks);
  const { formPopUpState, setFormPopUpState } = usePageContext();

  useEffect(() => {
    if (formPopUpState.popupState !== 'closed') {
      getFormData();
      const StripeTopCurrent = StripeTop.current;
      const animOpenMenu1 = gsap.to(StripeTopCurrent, {
        opacity: 0,
        y: 20,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });

      return () => {
        animOpenMenu1.kill();
      };
    } else {
      const StripeTopCurrent = StripeTop.current;

      const animOpenMenu1 = gsap.to(StripeTopCurrent, {
        opacity: 1,
        y: 0,
        ease: 'Power3.easeOut',
        duration: 0.5,
      });

      return () => {
        animOpenMenu1.kill();
      };
    }
  }, [formPopUpState.popupState]);

  const closePopup = () => {
    setFormPopUpState({ popupState: 'closed' });
  };

  const getFormData = () => {
    const filteredData = allFormsTitle?.filter(
      (item) => item?.formLink === formPopUpState.popupState,
    );
    return filteredData?.[0].formBlockRef;
  };

  const getFormTitle = () => {
    const filteredData = allFormsTitle?.filter(
      (item) => item?.formLink === formPopUpState.popupState,
    );
    return filteredData?.[0]._rawTitle?.[lang];
  };

  /////////////COMPONENT RENDER///////////////////////////////////////////////////////
  return (
    <div
      id="FormPop"
      style={{ width: windowSize.width }}
      className={`
      ${s.heightAuto}
      ${s.positionFixed}
      ${s.zIndex2000}
      ${s.alignItemsCenter}
      ${s.flexDirectionColumn}
      ${formPopUpState.popupState !== 'closed' ? s.visibilityVisible : s.visibilityHidden}
      `}
    >
      <div
        id="FormPopContent"
        className={`
           ${s.width100}
           ${s.height100}
           ${s.positionAbsolute}
           ${s.zIndex2000}
          
           `}
      >
        <div
          id="titleRow"
          className={`${s.width100}  ${s.height40Px}   ${s.flexDirectionRow} ${s.justifyContentFlexEnd}
        ${s.alignItemsFlexEnd}`}
        >
          <div
            id="closeButtonBox"
            onClick={() => closePopup()}
            className={`${s.width40Px} ${s.height40Px} ${s.cursorPointer} `}
          >
            <img
              className={`${s.width40Px} ${s.height40Px} ${s.padding10Px}`}
              src="/svgs/close_cross.svg"
              alt="SVG as an image"
            />
          </div>
        </div>

        <div
          id="formRow"
          style={{ height: windowSize.height - 40 }}
          className={`${s.width100}  
          ${s.paddingBottom20Px}
        
        `}
        >
          <div id="formContainer" className={` ${s.height100} `}>
            <div id="FormPopBox" className={`${s.height100} `}>
              <div
                // style={{ width: windowSize.width - getWrapperVal() * 2 }}
                className={`
                ${s.justifyContentCenter}
                ${s.alignItemsCenter}
                ${s.flexDirectionColumn}
               `}
              >
                <div
                  id="titleRow"
                  className={`
                  ${s.marginBottom20Px} 
                  `}
                >
                  <p
                    className={`
                    ${s.lineHeight1And1}
                    ${s.letterSpacing0Px}
                    ${s.whiteSpacePreWrap}
                    ${s.fontFamilyPaciencia}
                    ${s.fontSize36Px}
                    ${s.colorWhite}
                    ${s.textAlignCenter}
                   `}
                  >
                    {getFormTitle()}
                  </p>
                </div>
                <div style={{ height: windowSize.height - 130 }}>
                  <FormBlock
                    sanityMeetingLinks={sanityMeetingLinks}
                    item={getFormData() as SanityFormBlock}
                    isInPopUp={true}
                  />
                </div>
              </div>
            </div>

            {/* {formPopUpState.popupState === 'meeting' ? (
              <div
                className={`
              ${s.width100}
              ${s.height100}
              ${s.justifyContentFlexStart}
              ${s.alignItemsCenter}
              ${s.flexDirectionColumn}
              `}
              >
                <MeetingFormPop data={getFormData()} />
              </div>
            ) : (
              <FormPopBox data={getFormData()} />
            )} */}
          </div>
        </div>
      </div>
      <div
        id="formPopBackground"
        className={`
      ${s.width100}
      ${s.height100}
      ${s.positionFixed}
      ${s.backGroundColorDarkgreen}
      ${s.mixBlendModeMultiply}
      ${s.opacity09}
     
      `}
      />
    </div>
  );
};

export const formPopQuery = graphql`
  query formPop {
    sanityMeeting {
      mettingLinks {
        _rawLanguage
        langCode
        link
      }
    }

    allSanityTitlePopForm {
      nodes {
        id
        _rawTitle
        formLink

        formBlockRef {
          _type
          id
          _rawSuccessMessage
          image {
            asset {
              gatsbyImageData
            }
          }
          formSelector
        }
      }
    }
  }
`;

// fields {
//   _key
//   _type
//   en
//   es
// }
// _rawFields
// form {
//   _rawLabel
//   _rawPlaceholder
//   required
//   type
// }

// fieldList {
//   identifier
//   _rawLabel
//   _rawPlaceholder
//   selectList {
//     identifier
//     _rawLabel
//   }
//   _rawFields
//   required
//   type
// }
