export interface getHubFormInput {
  formId: string;
}

export const getHubFormAPI = async (formId) => {
  try {
    const response = await fetch(`/.netlify/functions/getHubForm?formId=${formId}`, {
      method: 'GET',
    });

    const res = await response.json();
    if (response.status === 200) {
      return await res;
    } else {
      throw new Error('Function API ERROR');
    }
  } catch (error) {
    throw error;
  }
};
