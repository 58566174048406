import React, {
  ReactNode,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { Link } from 'gatsby';
import * as s from '../../styles/main.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '../context/PageContext';
import { AccountSideMenuQuery } from 'graphql-types';
import { localizePath } from '../items/LotCardGrid';
import { UseFirebaseContext } from '../context/FirebaseContext';
interface LayoutProps {
  // children: ReactNode;
  // data: any;
  // userInfos: any;
}

export const AccountSideMenu: React.FC<LayoutProps> = ({}) => {
  const data = useStaticQuery<AccountSideMenuQuery>(accountSideQuery);

  const fireBaseContext = UseFirebaseContext();

  const menu = data?.allSanityAccountPage?.nodes;
  const { lang } = usePageContext();
  const hello = data?.allSanityAccountSettings?.nodes?.[0]._rawHelloLabel?.[lang];
  // useEffect(() => {
  //   console.log('user changed', fireBaseContext?.user);
  //   if (fireBaseContext?.user && fireBaseContext?.user !== null) {
  //     console.log('1 $$$$$$$$$$$$$$$$ userInfo ', fireBaseContext?.userInfo);
  //     console.log('1 $$$$$$$$$$$$$$$$ userInformations ', fireBaseContext?.userInformations);
  //     // setUserInfo(getUser());
  //   } else {
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log('user changed', fireBaseContext?.user);
  //   if (fireBaseContext?.user && fireBaseContext?.user !== null) {
  //     console.log('user$$$$$$$$$$$$$$$$ userInfo ', fireBaseContext?.userInfo);
  //     console.log('user$$$$$$$$$$$$$$$$ userInformations ', fireBaseContext?.userInformations);
  //     // setUserInfo(getUser());
  //   } else {
  //   }
  // }, [fireBaseContext?.user]);

  // useEffect(() => {
  //   console.log('user changed', fireBaseContext?.user);
  //   if (fireBaseContext?.user && fireBaseContext?.user !== null) {
  //     console.log('userInformations$$$$$$$$$$$$$$$$ userInfo ', fireBaseContext?.userInfo);
  //     console.log(
  //       'userInformations$$$$$$$$$$$$$$$$ userInformations ',
  //       fireBaseContext?.userInformations,
  //     );
  //     // setUserInfo(getUser());
  //   } else {
  //   }
  // }, [fireBaseContext?.userInformations]);

  const isActive = useCallback(
    (slug) => {
      const isThisMenuItemActive = location.pathname.includes(slug);
      return isThisMenuItemActive;
    },
    [location.pathname],
  );

  return (
    <div
      id="AccountSideMenu"
      className={`
      ${s.width300Px}
      ${s.height100}
      ${s.backGroundColorCream}
    `}
    >
      <div
        className={`
      ${s.width100}
      ${s.heightAuto}
      ${s.paddingTop40Px}
      ${s.paddingBottom30Px}
      ${s.wrapper40Px}
      ${s.displayFlex}
      ${s.flexDirectionColumn}
      ${s.gap30Px}
      
    `}
      >
        <div
          className={`
          ${s.width100}
          ${s.colorDarkgreen}
          ${s.fontSize30Px}
          ${s.fontFamilyPaciencia}
          ${s.textTransformCapitalize}
        `}
        >
          {`${hello} ${fireBaseContext?.userInfo?.['First name']}`}
        </div>
      </div>

      <div className={`${s.marginBottom40Px}`}>
        {menu.map((item, index) => {
          // console.log('item ', item);
          return (
            <Link key={index} to={localizePath(`/account/${item?.slug?.current}`, lang)}>
              <div
                className={`
                ${s.width100}
                ${s.colorDarkgreen}
                ${s.fontSize12Px}
                ${s.fontFamilyPoppinsBld}
                ${s.textTransformUppercase}
                ${s.textTransformUppercase}
                ${s.height40Px}
                ${s.wrapper40Px}
                ${s.displayFlex}
                ${s.alignItemsCenter}
                ${s.cursorPointer}
                ${isActive(item?.slug?.current) ? s.backGroundColorWhite : null}
              `}
              >
                {item?.titleText?._rawTitle?.[lang]}
              </div>
              <div
                className={`
                ${s.width100}
                ${s.height1Px}
                ${s.backGroundColorWhite}
              `}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export const accountSideQuery = graphql`
  query accountSideMenu {
    allSanityAccountSettings {
      nodes {
        _rawHelloLabel
      }
    }

    allSanityAccountPage(sort: { orderRank: ASC }) {
      nodes {
        id
        slug {
          current
        }
        pageTitle
        titleText {
          _rawTitle
        }
      }
    }
  }
`;
//     allSanityHeaderLogo {
//       nodes {
//         logoWhite {
//           asset {
//             gatsbyImageData(placeholder: NONE)
//           }
//         }
//         logoDark {
//           asset {
//             gatsbyImageData(placeholder: NONE)
//           }
//         }
//       }
//     }
//     allSanityDynamicPage(sort: { orderRank: ASC }) {
//       nodes {
//         id
//         pageTitle
//         _rawPageTitleMenu
//         orderRank
//         slug {
//           current
//         }

//         childPagesReferenceList {
//           id
//           pageTitle
//           _rawPageTitleMenu
//           slug {
//             current
//           }
//         }
//         pageSubdirectory {
//           slug {
//             current
//           }
//         }
//         activeInMainMenu
//         activeInNewMenu
//       }
//     }
//     allSanityEcoVilla(sort: { orderRank: ASC }) {
//       nodes {
//         id
//         slug {
//           current
//         }
//         name
//       }
//     }
//   }
// `;

// ${windowSize.width >= 750 ? s.paddingTopBottom30Px : s.paddingTopBottom16Px}
//${windowSize.width >= 750 ? s.wrapper40Px : s.wrapper20Px}
